<template>
  <v-dialog v-model="isOpen" persistent max-width="90%" min-width="1000px">
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <v-card class="dialog-container">
        <div
          class="
            main-container
            d-flex
            flex-row
            justify-space-between
            align-center
            flex-wrap
          "
          style="height: 70px;"
        >
          <span class="dialog-title">発注情報</span>
          <div class="button-container mt-2">
            <v-btn
              outlined
              color="primary"
              class="dialog-button"
              @click="isShowCancel = true"
            >
              やめる
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="dialog-button mr-0"
              :disabled="requestedOrderAll.length === 0"
              @click="isShowOrderPopup = true"
              >発注</v-btn
            >
            <v-icon @click="isShowCancel = true" class="mr-2">mdi-close</v-icon>
          </div>
        </div>
        <div class="main-container d-flex flex-row second-row flex-wrap ml-lg-6">
          <Label label="レンタル会社" width="200px" class="title-input-item">
            <v-select
              filled
              item-text="name"
              item-value="id"
              v-model="rentalSelected"
              :items="rentalList"
              dense
            ></v-select>
          </Label>
          <Label
            label="床高（m）"
            class="title-input-item"
            width="140px"
            v-show="false"
          >
            <InputText
              validation_label="床高（m）"
              :validation_rules="getRuleFrom()"
              :v-model="floor_height_min"
              :editable="true"
              name="floor_height_min"
              ref="floor_height_min"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <span class="headline" style="padding-top: 50px" v-show="false"
            >~</span
          >
          <Label
            label="床高（m）"
            class="title-input-item"
            width="140px"
            v-show="false"
          >
            <InputText
              validation_label="床高（m）"
              :validation_rules="getRuleTo()"
              :v-model="floor_height_max"
              :editable="true"
              name="floor_height_max"
              ref="floor_height_max"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <div class="d-flex flex-column mb-md-6 mb-sm-6" v-show="false">
            <Label label="昇降" class="title-input-item" v-show="false">
              <TabSelect
                name="lifting_method"
                :items="ELEVATION_LIST"
                :editable="true"
                :values="searchTabAdd"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
          <div class="d-flex flex-column mb-md-6 mb-sm-6" v-show="false">
            <Label label="足回り" class="title-input-item" v-show="false">
              <TabSelect
                name="undercarriage"
                :items="WHEEL_LIST"
                :editable="true"
                :values="searchTabAdd"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
          <div class="d-flex flex-column mb-md-6 mb-sm-6" v-show="false">
            <Label label="作業床" class="title-input-item" v-show="false">
              <TabSelect
                name="working_floor"
                :items="FLOOR_LIST"
                :editable="true"
                :values="searchTabAdd"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
          <div class="d-flex justify-end ml-auto mt-lg-10 pt-1 mt-md-6 mt-sm-6">
            <v-btn
              depressed
              color="primary"
              class="dialog-button mr-lg-4"
              @click="fetchData()"
              :disabled="invalid"
            >
              検索
            </v-btn>
          </div>
        </div>
        <div class="main-content-container">
          <div class="main-content-inner">
            <div
              v-for="(item, index) in dragList"
              :key="'drag' + index"
              class="d-flex show-color"
            >
              <div
                class="d-flex flex-column content-col"
                v-if="index === 0 || item.isShow"
              >
                <div class="content-header" v-if="index !== 0">
                  <span class="title">{{ item.title }}</span>
                  <div class="number" :class="{ active: item.item.length > 0 }">
                    {{ item.item.length || 0 }}
                  </div>
                </div>
                <div class="badge" v-else>
                  {{ item.title }}（{{ totalFirstColumn || 0 }}）
                </div>
                <div class="content" :id="`dragList-${index}`">
                  <v-checkbox
                    v-model="item.isChecked"
                    label="全て選択"
                    v-if="index > 0 && item.item.length"
                    @change="onChangeCompanyCheckbox(index)"
                  ></v-checkbox>
                  <div
                    v-if="
                      !isLoadingList &&
                      isEmptyList &&
                      index === 0 &&
                      item.item.length === 0
                    "
                    class="d-flex justify-center"
                  >
                    <span> データがありません。 </span>
                  </div>
                  <draggable
                    class="draggable"
                    v-bind="dragOptions"
                    :list="item.item"
                    @change="updateListChecked()"
                    @end="onDragRequest"
                  >
                    <div
                      v-for="(request, cIndex) in item.item"
                      :key="`drag${index}-${cIndex}`"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="content-item d-flex flex-row"
                          :elevation="hover ? 16 : 2"
                        >
                          <v-checkbox
                            v-if="index > 0"
                            v-model="request.isChecked"
                            @change="onChangeSingleCheckbox(index)"
                          >
                          </v-checkbox>
                          <div class="d-flex flex-column">
                            <span class="content-name">
                              {{ getFloor(request.floor_height_min, request.floor_height_max) }}
                              {{ request.lifting_method }}
                              {{ request.undercarriage }}
                              {{ request.working_floor }}
                            </span>
                            <span class="content-number">
                              {{ request.company_partner_name }}
                            </span>
                            <span
                              class="content-number"
                              v-if="
                                index > 0 && Object.keys(request.gate.name) != 0
                              "
                            >
                              搬入口 : {{ request.gate.name }}
                            </span>
                          </div>
                        </v-card>
                      </v-hover>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <Popup width="480px" :dialog="isShowCancel">
            <ConfirmCloseDialog
              v-model="isShowCancel"
              :title="WRN_0002.TITLE"
              :text1="WRN_0002.TEXT1"
              :text2="WRN_0002.TEXT2"
              textSubmit="閉じる"
              :warning="true"
              @close="isShowCancel = false"
              @yes="close()"
            />
          </Popup>
          <Popup width="480px" :dialog="isShowOrderPopup">
            <OrderModal
              v-model="isShowOrderPopup"
              :data="requestedOrderAll"
              @close="isShowOrderPopup = false"
              @submit="onSubmitOrder(), (orderSuccess = true)"
            />
          </Popup>
          <v-btn
            outlined
            color="primary"
            class="dialog-button"
            @click="onReset()"
            :disabled="requestedOrderChecked.length === 0"
            v-if="requestedOrderAll.length"
          >
            元に戻す
          </v-btn>
        </div>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { WRN_0002 } from "@/constants/MESSAGE";
import { Store } from "@/store/Store";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import { ELEVATION_LIST, FLOOR_LIST, WHEEL_LIST } from "@/constants/ORDER_MODAL";
import draggable from "vuedraggable";
import { PARTNER_ROLE, RENTAL_ROLE } from "@/constants/MACHINE";
import { cloneDeep } from "lodash";
import OrderModal from "./components/OrderModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from "@/components/loading/Loading";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";

const default_company = {
  id: null,
  name: "",
};

const default_dragList = [
  {
    title: "未発注",
    item: [],
  },
];

const default_params = {
  floor_height_min: 0,
  floor_height_max: 999.99,
};

export default {
  components: {
    ConfirmCloseDialog,
    Popup,
    draggable,
    OrderModal,
    ValidationProvider,
    ValidationObserver,
    Loading,
    Select,
    Label,
    InputText,
    TabSelect,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    requestIds: {
      type: Array,
      default: [],
    },
  },
  computed: {
    dragOptions() {
      return {
        disabled: false,
        scrollSensitivity: "250",
        forceFallback: true,
        group: "drag",
      };
    },
    requestedOrderChecked() {
      return this.dragList
        .filter((list) => list.id)
        .filter((list) => list.item?.filter?.((x) => x.isChecked)?.length)
        .map((list) => ({
          rental_company_id: list.id,
          rental_company_name: list.title,
          ids: list.item
            .filter((x) => x.isChecked)
            .map((x) => x.machine_request_details_id),
        }));
    },
    requestedOrderAll() {
      return this.dragList
        .filter((list) => list.id)
        .filter((list) => list.item?.length)
        .map((list) => {
          return {
            rental_company_id: list.id,
            rental_company_name: list.title,
            ids: list.item.map((x) => x.machine_request_details_id),
            orderIds: list.item.map((x) => ({
              id: x.machine_request_details_id,
              delivery_gate: x.gate.id,
            })),
          };
        });
    },
    requestedOrderMachineRequestArray() {
      return this.requestedOrderAll
        .map((x) => x.ids)
        .reduce((acc, cur) => acc.concat(cur), []);
    },
    canSearch() {
      const isValidMin = this.$refs["floor_height_min"]?.isValid;
      const isValidMax = this.$refs["floor_height_max"]?.isValid;
      return isValidMin && isValidMax;
    },
  },
  data() {
    return {
      // Constant
      ELEVATION_LIST,
      WHEEL_LIST,
      FLOOR_LIST,
      WRN_0002,
      PARTNER_ROLE,
      RENTAL_ROLE,
      default_company,

      // UI
      isOpen: false,
      isShowCancel: false,
      isShowOrderPopup: false,
      isLoadingList: false,
      isEmptyList: false,
      isFirstCall: true,
      totalFirstColumn: 0,

      // Variable
      floor_height_min: "",
      floor_height_max: "",
      rentalSelected: null,
      partnerSelected: null,
      searchTabAdd: {
        lifting_method: [],
        undercarriage: [],
        working_floor: [],
      },
      field_id: 0,
      needUpdate: false,

      // List
      rentalList: [],
      partnerList: [],
      dragList: cloneDeep(default_dragList),
      _dragList: {},
      disabledBtn: true,
      disabledSetting: true,
      orderSuccess: false,
    };
  },
  async mounted() {
    this.isOpen = this.isShow;
    await this.getData();
    await this.fetchData();
    this.watchField();
  },
  watch: {
    dragList: {
      handler: function (newValue) {
        this.disabledBtn = true;
        this.disabledSetting = true;
        newValue.forEach((data, index) => {
          if (index !== 0) {
            if (data.item.length) {
              this.disabledBtn = false;
              const isChecked = data.item.find((element) => element.isChecked);
              if (isChecked) this.disabledSetting = false;
            }
          } else {
            this.totalFirstColumn = data.item.length || 0;
          }
        });
      },
      deep: true,
    },
    isOpen: {
      handler: function (newValue) {
        if (newValue === false) this.$emit("close", this.needUpdate);
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.isShowCancel = false;
      this.isOpen = false;
      this.$emit("close", 'close');
    },
    watchField() {
      this.$watch(
        () => Store.getters["GlobalHeader/getCurrentSite"],
        (data, oldData) => {
          if (data?.field_id !== oldData?.field_id) this.fetchData();
        },
        {
          deep: true,
          immediate: true,
        }
      );
    },
    async getData() {
      this.getLocalData();
      await this.getPartnerCompany();
      await this.getRentalCompany();
    },
    getLocalData() {
      // Field id
      this.field_id = JSON.parse(
        sessionStorage.getItem("CURRENT_SITE")
      ).field_id;
    },
    async getPartnerCompany() {
      // Assign body
      const body = {
        field_id: this.field_id,
        role: PARTNER_ROLE,
      };

      const res = await Store.dispatch(
        "Machine/getListFieldCompanyByRole",
        body
      );
      const list = res?.data?.contents?.entries ?? [];
      this.partnerList = [{ ...default_company }, ...list];
    },
    async getRentalCompany() {
      // Assign body
      const body = {
        field_id: this.field_id,
        role: RENTAL_ROLE,
      };

      // Reset drag list
      this.dragList = cloneDeep(default_dragList);
      this.rentalSelected = null;

      const res = await Store.dispatch(
        "Machine/getListFieldCompanyByRole",
        body
      );
      const list = res?.data?.contents?.entries ?? [];
      this.rentalList = [{ ...default_company }, ...list];
      this.dragList = cloneDeep([
        ...default_dragList,
        ...this.rentalList
          .filter((company) => company.name)
          .map((company) => ({
            id: company.id,
            item: [],
            title: company.name,
            isChecked: false,
            isShow: this.rentalSelected
              ? company.id === this.rentalSelected
              : true,
          })),
      ]);
    },
    onChangeRental() {
      for (let i = 0; i < this.dragList.length; i++) {
        this.dragList[i].isShow = this.rentalSelected
          ? this.dragList[i].id === this.rentalSelected
          : true;
      }
    },
    async fetchData() {
      // Check canSearch
      if (!this.canSearch) return;

      // Set UI state
      this.isEmptyList = true;
      this.isLoadingList = true;
      this.onChangeRental();

      // Assign params
      const body = {
        field_id: this.field_id,
        company_partner_id: this.partnerSelected,
        floor_height_min:
          this.floor_height_min === ""
            ? default_params.floor_height_min
            : this.floor_height_min,
        floor_height_max:
          this.floor_height_max === ""
            ? default_params.floor_height_max
            : this.floor_height_max,
        working_floor: this.searchTabAdd.working_floor,
        undercarriage: this.searchTabAdd.undercarriage,
        lifting_method: this.searchTabAdd.lifting_method,
        machine_request_ids: this.requestIds,
      };

      const res = await Store.dispatch(
        "Machines/getListMachineRequestDetail",
        body
      );
      if (!res.hasError && this.orderSuccess) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.orderSuccess = false;
        this.$emit("close");
      }
      const list =
        res?.data?.contents?.entries?.filter?.(
          (x) =>
            !this.requestedOrderMachineRequestArray.includes(
              x?.machine_request_details_id
            )
        ) ?? [];

      // Reset UI state
      this.isLoadingList = false;
      this.isEmptyList = !list.length;

      // Assign and sync
      this.dragList[0].item = list.map((x) => ({
        ...x,
        isChecked: false,
        gate: {
          id: null,
          name: "",
        },
      }));
      this.totalFirstColumn = this.isFirstCall
        ? this.dragList[0].item.length
        : this.totalFirstColumn;
      this.isFirstCall = false;
    },
    onReset() {
      // Get all checked item
      const checked = this.dragList
        .filter((list) => list.id)
        .filter((list) => list.item?.length)
        .filter((list) => list.item?.filter?.((x) => x.isChecked)?.length)
        .map((list) => list.item.filter((x) => x.isChecked))
        .reduce((acc, cur) => acc.concat(cur), [])
        .map((x) => ({
          ...x,
          isChecked: false,
          gate: { id: null, name: "" },
        }));

      // Remove all checked item
      for (let i = 1; i < this.dragList.length; i++)
        this.dragList[i].item = this.dragList[i].item.filter(
          (x) => !x.isChecked
        );

      // Add checked to first row
      this.dragList[0].item.push(...checked);
    },
    onChangeCompanyCheckbox(index) {
      for (let i = 0; i < this.dragList[index].item.length; i++)
        this.dragList[index].item[i].isChecked = this.dragList[index].isChecked;
    },
    onChangeSingleCheckbox(index) {
      // Sanitize
      if (!this.dragList[index]?.item?.length) return;

      this.dragList[index].isChecked =
        this.dragList[index].item.filter((x) => x.isChecked).length ===
        this.dragList[index].item.length;
    },
    updateListChecked() {
      for (let i = 0; i < this.dragList.length; i++)
        this.onChangeSingleCheckbox(i);
    },
    async onSubmitOrder() {
      this.isOpen = false;
      this.isShowOrderPopup = false;
      await this.getRentalCompany();
      await this.fetchData();
      this.needUpdate = true;
    },
    getRuleFrom() {
      let rule = "";
      if (this.floor_height_min && this.floor_height_max) {
        rule = `compare-min:${this.floor_height_max},床高（m）（左）,床高（m）（右）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    getRuleTo() {
      let rule = "";
      if (this.floor_height_min && this.floor_height_max) {
        rule = `compare-max:${this.floor_height_min},床高（m）（右）,床高（m）（左）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    onChangeSearchParams({ name, value }) {
      const existArr = ["lifting_method", "undercarriage", "working_floor"];
      existArr.includes(name)
        ? (this.searchTabAdd[name] = value)
        : (this[name] = value);
    },
    onDragRequest(event) {
      // Get indexes
      const fromId = event.from.parentElement.id;
      const toId = event.to.parentElement.id;
      const fromIndex = fromId.slice("dragList-".length, fromId.length);
      const toIndex = toId.slice("dragList-".length, toId.length);

      if (fromIndex !== toIndex) {
        this.dragList[toIndex].item[event.newIndex].isChecked = false;

        if (toIndex === "0")
          this.dragList[toIndex].item[event.newIndex].gate = {
            id: null,
            name: "",
          };

        this.onChangeSingleCheckbox(+fromIndex);
        this.onChangeSingleCheckbox(+toIndex);
      }
    },
    getFloor(floorHeightMin, floorHeightMax) {
      if (floorHeightMin === null && floorHeightMax !== null) {
        return `～${ floorHeightMax }m`;
      } else if (floorHeightMax === null & floorHeightMin !== null) {
        return `${ floorHeightMin }m～`;
      } else if (floorHeightMin !== null && floorHeightMax !== null) {
        return `${ floorHeightMin }m～${ floorHeightMax }m`;
      } else {
        return ``;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/fonts.scss";
@import "@/assets/scss/themes.scss";

$border_bottom: 1px solid $color_border_accent;

* {
  font-family: $normal_font;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.dialog-button {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 1.25px;
  min-width: 90px !important;
}

.dialog-button-default {
  height: 28px !important;
  min-width: 50px !important;
  padding: 0 12.4444444444px !important;
  font-size: .75rem !important;
}

.dialog-container {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .main-container {
    padding: 5px 8px 0 8px!important;
  }

  .dialog-title {
    letter-spacing: 0.18px;
    font-size: 20px!important;
    font-weight: 600;
    color: $color_primary;
  }

  .button-container {
    > :not(:last-child) {
      margin-right: 20px;
    }

    > :last-child {
      margin-left: 15px;
    }
  }

  > .second-row {
    border-bottom: $border_bottom;

    > :not(:last-child) {
      margin-right: 10px;
    }

    > .select {
      max-width: 130px;
    }

    .number-input-container {
      > :not(:last-child) {
        margin-right: 6px;
      }

      > .number-input {
        max-width: 98px;
      }
    }

    > .headline {
      font-size: 30px !important;
      line-height: 24px;
      color: #000000;
    }
  }

  > .main-content-container {
    padding: 17px 34px 0;
    overflow-x: auto;
    display: flex;
    height: 100%;

    > * {
      display: flex;
    }

    > .main-content-inner {
      > :not(:last-child) {
        > :first-child {
          margin-right: 30px;
        }
      }

      .show-color:first-child .content {
        background: rgba(39, 79, 194, 0.08);
      }
    }

    .content-col {
      > .content-header {
        display: flex;
        align-items: center;

        :first-child {
          margin-right: 15px;
        }

        > .title {
          font-size: 16px !important;
          color: $color_text_main;
          text-overflow: ellipsis;
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 400;
        }

        $circle: 35px;

        > .number {
          width: $circle;
          height: $circle;
          border-radius: $circle;
          background: rgba(0, 0, 0, 0.38);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.38);

          &.active {
            background: #1f5697;
            color: #fff;
          }
        }
      }

      > :first-child {
        margin-bottom: 10px;
      }

      > .badge {
        padding: 8px;
        text-align: center;
        background: #e5951d;
        border-radius: 16px;
        width: 100px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.87);
      }

      .content {
        width: 250px;
        height: 100%;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 5px 5px 0px 0px;
        padding: 5px 10px;
        overflow-y: auto;
        transition: 0.5s linear;

        > :first-child {
          padding: 0;
          padding-top: 5px;
          padding-bottom: 6px;
        }

        > .draggable {
          width: 100%;
          height: calc(100% - 35px);

          > :not(:last-child) {
            margin-bottom: 10px;
          }
          > :last-child {
            padding-bottom: 10px;
          }
        }

        .content-item {
          padding: 10px;
          padding-bottom: 5px !important;
          width: 100%;
          font-size: 14px;
          transition: 0.5s linear;

          .content-name {
            font-weight: 500;
            letter-spacing: 0.1px;
            color: rgba(0, 0, 0, 0.87);
          }

          .content-number {
            line-height: 20px;
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  > .footer {
    margin: 22px 0px;

    > * {
      float: right;
      margin-right: 20px;
    }
  }

  ::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 40px;
  }

  ::v-deep .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 7px;
  }
}

:deep(.button-toggle) {
  max-height: 30px;
  background-color: #f8f8f8;
  border: none !important;

  > .v-btn__content {
    font-size: 16px;
    color: #a1a1a1;
  }

  &.v-btn--active {
    background-color: $color_primary;

    > .v-btn__content {
      color: #ffffff !important;
    }
  }

  &.v-btn::before {
    background-color: transparent !important;
    transition: none !important;
  }
}

:deep(.content) {
  .v-input--selection-controls__ripple {
    display: none;
  }

  .v-input--selection-controls__input {
    margin-right: 6px;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-messages {
    display: none;
  }

  .v-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
  }
}

:deep(.content-item) {
  .v-input {
    margin: 0;
    padding: 0;
  }
}

.button-toggle-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $color_text_main;
  margin-bottom: 10px;
}

:deep(.second-row) {
  .v-input__slot {
    margin-bottom: 0;
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

::v-deep .tabSelectwrapper {
  height: 10px;

  .tabLabel {
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.25px;
  }
}
</style>
