<template>
  <FormDialog>
    <template #header>
      <Loading v-if="isLoading"/>
      <v-app-bar flat height="50px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          所属情報
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onStopEditCF"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="hundleSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key" v-if="actions !== 'create' || key !== 'FieldGroupUserInfo'">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items  v-model="tab">
          <v-tab-item>
            <BasicFormPage
              :editable="editable"
              :companyEditable="companyEditable"
              :detailData="dataRegister.field_group"
              :dataSelectCompany="dataSelectCompany"
              :fieldId="fieldId"
              :actions="actions"
              @updateCompanyEditable="updateCompanyEditable"
              :errors="errors"
              @openListCompany="openListCompany"
              @searchListCompany="searchListCompany"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FieldGroupUserFormPage
              :editable="editable"
              :companyEditable="companyEditable"
              :detailData="dataRegister.field_group_users"
              :dataSelectCompany="dataSelectCompany"
              :errors="errors"
              :fieldId="fieldId"
              :fieldGroupData="dataRegister.field_group"
              @updateCompanyEditable="updateCompanyEditable"
              @openListCompany="openListCompany"
              @searchListCompany="searchListCompany"
              @getFieldGroupInfo="getFieldGroupInfo"
              @getListFieldGroup="callGetItems"
              @closeUpdateStatus="onStopEdit"
              :mainHeight="params.mainHeight"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate"/>
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="onStopEdit"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ListCompany from "@/components/forms/company/popup/ListCompany";
import {Store} from "@/store/Store.js";
import FormFieldGroup from "./FormFieldGroup";
import BasicFormPage from "./BasicFormPage";
import FieldGroupUserFormPage from "./FieldGroupUserFormPage";
import ShowQR from "@/components/qr/ShowQR";
import ConfirmInviteUserForm from "@/components/forms/fieldUsers/Popup/ConfirmInviteUserForm";
import {USER_INIT} from "@/constants/FIELD_USERS";
import Loading from "@/components/loading/Loading";
import {COMPANY_SCENE} from "@/constants/COMMON";
import _ from "lodash";

const FIELD_GROUP_STORE = "FieldGroups";
//const FIELD_GROUP_USER_STORE = "FieldGroupUsers";

const FORMS = {
  BasicInfo: {
    id: 1,
    title: "基本情報"
  },
  FieldGroupUserInfo: {
    id: 2,
    title: "利用者"
  },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: false,
      companyEditable: true,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      idSelect: null,
      isShowItemForm: false,
      dataSelectCompany: null,
      dataRegister: {},
      keySearch: null,
      dataListCompany: {},
      isShowConfirmInvite: false,
      USER_INIT,
      isLoading: false,
      COMPANY_SCENE,
      localItem: {} // ローカルコピーの追加
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    BasicFormPage,
    FieldGroupUserFormPage,
    Popup,
    FormFieldGroup,
    FormDialog,
    ListCompany,
    ShowQR,
    ConfirmInviteUserForm,
    Loading
  },
  props: {
    isNewItem: Boolean,
    actions: String,
    item: Object,
    fieldId: Number
  },

  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.fieldId,
      (fieldId) => {
        this.fieldId = fieldId
      },
      {immediate: true, deep: true}
    );
    this.$watch(
      () => Store.getters[`${FIELD_GROUP_STORE}/getIsLoading`],
      (data) => {
        if (data == true) {
          this.isLoading = data;
        } else {
          this.isLoading = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
        () => Store.getters[`Register/getIsLoading`],
        (data) => {
          if (data == true) {
            this.isLoading = data;
          } else {
            this.isLoading = false;
          }
        },
        {
          immediate: true,
          deep: true,
        }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.localItem = { ...data };
        this.dataRegister.field_group = data;
        this.dataRegister.field_group_users = data.field_group_users.contents.entries;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.errors,
      (data) => {
        this.valid = true;
        const arrErr = Object.entries({...data});
        for (let i = 0; i < arrErr.length; i++) {
          const item = arrErr[i];
          if (item[1].length > 0) {
            this.valid = false;
            return;
          }
        }
      }
    );
  },

  methods: {
    formUpdate(params) {
      this.localItem = { ...params };
      this.dataRegister.field_group = { ...params };
    },

    onStopEdit() {
      if (this.actions == 'create' || this.isClickCloseBtn) {
        this.closeFieldGroupForm();
      } else {
        this.localItem = _.cloneDeep(this.getDataDetail);
        this.editable = false;
        this.isShowConfirmDialog = false;
      }
    },
    onStopEditCF() {
      this.isShowConfirmDialog = true;
    },
    onEditable() {
      this.editable = true;
    },
    async hundleSubmit() {
      const fieldId = this.fieldId;

      if (this.actions == 'create') {
        const createParam = {
          field_groups: [
            {
              field_id: fieldId,
              group_name: this.dataRegister.field_group.name,
              leader_name: this.dataRegister.field_group.leader_name
            }
          ]
        };

        const res = await Store.dispatch(`${FIELD_GROUP_STORE}/addFieldGroupInfo`, createParam);
        if (!res.hasError && res.data.status_code === 200) {
          Store.dispatch("Toast/show", {
              status: "200",
              message: res.data.message
            },
            {
              root: true
            }
          );
        }
      } else if (this.actions == 'edit') {
        const editParam = {
          field_group: {
            field_id: fieldId,
            field_group_id: this.dataRegister.field_group.id,
            group_name: this.dataRegister.field_group.name,
            leader_name: this.dataRegister.field_group.leader_name
          },
          field_group_users: []
        };

        const res = await Store.dispatch(`${FIELD_GROUP_STORE}/updateFieldGroupInfo`, editParam);
        if (!res.hasError && res.data.status_code === 200) {
          Store.dispatch("Toast/show", {
              status: "200",
              message: res.data.message
            },
            {
              root: true
            }
          );
        }
      }
      this.closeForm();
      this.$emit("getItems");
    },

    closeFieldGroupForm() {
      this.$emit("closeFieldGroupForm")
    },

    async saveData() {
      this.isShowConfirmInvite = false;
      let params = {...this.dataRegister};
      params["field_id"] = this.fieldId;
      const res = await Store.dispatch(`FieldUsers/addFieldUserInfo`, params);
      if (!res.hasError && res.data.status_code === 200) {
        this.dataRegister.field_users = res.contents.entries.field_users;
        this.editable = false;
        this.companyEditable = false;
        this.callGetItems();
        await Store.dispatch("Toast/show", {
            status: "200",
            message: res.data.message
          },
          {
            root: true
          }
        );
        this.closeFieldGroupForm();
      }
    },

    updateValidate(params) {
      const {valid, errors} = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      this.isShowConfirmDialog = false;
      this.closeFieldGroupForm();
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.closeFieldGroupForm();
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    handCheckCompany(data) {
      if (data) {
        this.dataSelectCompany = data;
        this.closeItemForm();
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelConfirm() {
      this.isShowConfirmInvite = false;
    },

    openListCompany(name) {
      this.keySearch = name.keyword
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },

    updateCompanyEditable(value) {
      this.companyEditable = value;
    },

    callGetItems() {
      this.$emit("getItems");
    },

    async searchListCompany() {
      const res = await Store.dispatch('Register/getCompany', {
        name: this.keySearch
      });
      if (!res.hasError && res?.data?.count > 0) {
        this.dataListCompany = res.data.corporation
        this.isShowItemForm = true;
      } else if (res.hasError) {
        Store.dispatch("Error/show", {
            status: "422",
            message: "エラー: " + res.response.data.message
          },
          {
            root: true
          }
        );
      } else {
        Store.dispatch("Error/show", {
            status: "422",
            message: "エラー: データがありません。"
          },
          {
            root: true
          }
        );
      }
    },

    validateEmail(value) {
      return value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/);
    },

    async getFieldGroupInfo() {
      let params = { id: this.item.id}
      const siteInfo = await Store.dispatch(
        `${FIELD_GROUP_STORE}/getDetail`,
        {params}
      );
      if (siteInfo?.data?.contents?.entries) {
        this.localItem = {...siteInfo?.data?.contents?.entries};
        this.dataRegister.field_group = this.localItem;
        this.dataRegister.field_group_users = this.localItem.field_group_users.contents.entries;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title-popup {
  color: #274FC2;
}

.popup-company {
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
