const FIELD_INITIAL_ITEM = {
  entries: {
    fields: {
      max_days_change_place_notice: 30,
      max_bookable_days: 30,
      end_time_of_day: '00:00',
    },
    field_places: [],
    field_gates: [],
  },
};
const MINS_ON_HOUR = ["00", "15", "30", "45"];

const createEnterDayTime = () => {
    let result = [];
    let i = 0;
    while (i <= 23) {
      for (let m of MINS_ON_HOUR) {
        if (i === 23 && (m == 15 || m == 30 || m ==45)) break
        let hour = i < 10 ? "0" + i : i;
        let element = {
          id: hour + ":" + m,
          name: hour + ":" + m,
        };
        result.push(element);
      }
      i++;
    }
    result.push({ id: "12:00", name: "12:00" });
    return result;
  };


const FIELDS_TAB = {
    FieldPage1: { id: 1, title: "基本情報" },
    FieldPage2: { id: 2, title: "基本設定" },
    FieldPage3: { id: 3, title: "留置場所設定" },
    FieldPage4: { id: 4, title: "ゲート設定" },
};

const FIELDS_TAB_3 = 2;
const FIELDS_TAB_4 = 3;

const COMPANY_INITAL_ITEM = {
    company: {
      name: "",
      name_kana: "",
      corporate_status_id: null,
      corporate_rating_position: "",
      corporate_classification: "",
      corporate_number: null,
      logo_image: "",
      postal_code: "",
      cmn_mst_prefecture_id: null,
      city: "",
      address: "",
      tel: "",
      fax: "",
      representative: "",
      representative_email : "",
      employment_manager: "",
      catergory_id: null,
      com_info_id: null,
      ccus_business_id: null,
      remarks: null,
    } 
};

const ENTER_DAY_TIME_ARRAY = createEnterDayTime();

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "現場情報";
const FIELD_TITLE = "現場";

const FIELD_TABLE_LABELS = [
    {
      text: "契約",
      align: "left",
      sortable: false,
      value: "active_flg",
      width:"15%",
    },
    {
      text: "現場コード",
      value: "field_code",
      align: "left",
      sortable: false,
      width:"12%",
    },
    {
      text: "元請",
      value: "company_name",
      align: "left",
      sortable: false,
      width:"14%",
    },
    {
      text: "現場名",
      value: "name",
      align: "left",
      sortable: false,
      width:"15%",
    },
    {
      text: "第一責任者",
      value: "manager_user",
      align: "left",
      sortable: false,
      width:"15%",
    },
    {
      text: "竣工予定年月",
      value: "field_end_date",
      align: "left",
      sortable: false,
      width:"12%",
    },
    {
      text: "",
      value: "action",
      align: "left",
      sortable: false,
      width:"12%",
    },
];

const FIELD_TABLE_LABELS_USER = [
  {
    text: "",
    align: "left",
    sortable: false,
    value: "active_flg",
    width:"10%",
  },
  {
    text: "現場コード",
    value: "field_code",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "元請",
    value: "company_name",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "現場名",
    value: "name",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "第一責任者",
    value: "manager_user",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "所属名",
    value: "group_name",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "竣工予定年月",
    value: "field_end_date",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "",
    value: "action",
    align: "left",
    sortable: false,
    width:"10%",
  },
];
const FIELD_SORT_ITEMS = [
  {
    id: "1",
    name: "元請名",
  },
  {
    id: "2",
    name: "現場名",
  },
  {
    id: "3",
    name: "竣工予定年月",
  },
];   
 
const FIELD_SELECT_TAB = [
  { id: 0, name: "申請中" },
  { id: 1, name: "利用中" },
  { id: 2, name: "終了" },
];

const FIELD_APPROVAL_TAB = [
  { id: 0, name: "手動承認" },
  { id: 1, name: "自動承認" },
];

const FIELD_SELECT_TAB_REPORT_FIRST =  [
    { id: 0, name: "垂" },
    { id: 1, name: "直" },
    { id: 2, name: "屈" },
];

const FIELD_SELECT_TAB_REPORT_SECOND =  [
    { id: 1, name: "ホ" },
    { id: 2, name: "ク" },
];

const FIELD_SELECT_TAB_REPORT_THREE =  [
    { id: 1, name: "普" },
    { id: 2, name: "ス" },
    { id: 3, name: "バ" },
];

const FIELD_APPROVAL_FREQUENCY = [
  { id: "0.03", name: "3分ごと"},
  { id: "0.05", name: "5分ごと"},
  { id: "0.5", name: "30分ごと"},
  { id: "1", name: "1時間ごと"},
  { id: "2", name: "2時間ごと"},
  { id: "4", name: "4時間ごと"},
  { id: "6", name: "6時間ごと"},
  { id: "12", name: "12時間ごと"},
  { id: "24", name: "24時間ごと"},
]

export {
    FORM_TITLE,
    FIELD_TABLE_LABELS,
    FIELD_TABLE_LABELS_USER,
    FIELD_SORT_ITEMS,
    FIELD_TITLE,
    FIELD_INITIAL_ITEM,
    FIELDS_TAB,
    COMPANY_INITAL_ITEM,
    ENTER_DAY_TIME_ARRAY,
    FIELD_SELECT_TAB,
    FIELD_SELECT_TAB_REPORT_FIRST,
    FIELD_SELECT_TAB_REPORT_SECOND,
    FIELD_SELECT_TAB_REPORT_THREE,
    FIELDS_TAB_3,
    FIELDS_TAB_4,
    FIELD_APPROVAL_TAB,
    FIELD_APPROVAL_FREQUENCY,
};
