<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page="{ layoutParams }">
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab7"
          >
            <template #tableHeader="{ updateHeader }">
              <v-row>
                <v-col>
                  <TableHeader
                    ref="tableHeader"
                    :pageTitle="TITLE"
                    :isDelete="false"
                    :isAddNew="false"
                    :isClassify="false"
                    :updateHeader="updateHeader"
                  >
                    <SearchFormWrapper>
                      <!-- ロール -->
                      <Label label="期間" class="title-input-item" style="max-width: 224px">
                        <InputMonthpicker
                          name="start_use_field_date"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                          :flagNull="true"
                          validation_label="期間 (左) "
                          :validation_rules="getStartMonthRules()"
                        />
                      </Label>
                      <span class="mt-12 ml-3">
                        <v-icon>mdi-tilde</v-icon>
                      </span>
                      <Label label="期間" class="title-input-item" style="max-width: 224px">
                        <InputMonthpicker
                          name="end_use_field_date"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                          :flagNull="true"
                          validation_label="期間（右）"
                          :validation_rules="getEndMonthRules()"
                        />
                      </Label>
                      <Label label="レンタル会社" class="title-input-item">
                        <Select
                          name="company_id"
                          :values="formValues.fields"
                          :items="companyItem"
                          item_text="name"
                          :editable="true"
                          validation_label="協力会社"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="ナンバー" class="title-input-item">
                        <InputText
                          name="machines_number_plate"
                          :values="searchParams"
                          :editable="true"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="昇降" class="title-input-item">
                        <TabSelect
                          name="agreements_first"
                          :items="FIELD_SELECT_TAB_REPORT_FIRST"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="足回り" class="title-input-item">
                        <TabSelect
                          name="agreements_second"
                          :items="FIELD_SELECT_TAB_REPORT_SECOND"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="作業床" class="title-input-item last-item-tab">
                        <TabSelect
                          name="agreements_three"
                          :items="FIELD_SELECT_TAB_REPORT_THREE"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <!--  (共通) 検索ボタン -->
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mr-6 mt-10 btn-on-search"
                        color="primary"
                        depressed
                        @click="onSearch(invalid)"
                      >
                        検索
                      </v-btn>
                    </SearchFormWrapper>
                  </TableHeader>
                </v-col>
              </v-row>

            <!--
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--
                (共通) ソート
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS_BY_MACHINE"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams(...arguments, invalid)"
                class="ml-3 sort_report"
              />
            </TableSortWrapper>
          </template>
          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                <v-tab 
                  v-for="tab in TAB" 
                  :key="tab.id" 
                  @click="changeTab(tab.id)" 
                  color="primary" 
                  class="tab-machine primary--text">
                  {{tab.name}}
                </v-tab>
              </v-tabs>
            </template>
            <v-data-table
              item-key="bookingId"
              v-model="selectedItems"
              :headers="TABLE_LABELS_RENTAL"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 110 : tableHeight - 155"
              fixed-header
              hide-default-footer
              disable-sort
              class="v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @item-selected="updateSelectedItems"
              @toggle-select-all="selectAllItems"
              @update:searchParams.currentPage="$vuetify.goTo(0)"
              :key=searchParams.currentPage
            >
              <template v-slot:top>
                <v-btn
                  color="primary"
                  depressed
                  class="ml-4 mt-2 mb-2"
                  @click="exportCsv()"
                  left
                  :disabled="disableRemoveBtn"
                >
                  出力
                </v-btn>
              </template>
              <template v-slot:[`item.companyName`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertName(item.companyName, 50) }}
                </div>
              </template>
              <template v-slot:[`item.userName`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertName(item.userName, 50) }}
                </div>
              </template>
              <template v-slot:[`item.userPhone`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertName(item.userPhone, 50) }}
                </div>
              </template>
              <template v-slot:[`item.numberPlate`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ item.numberPlate }}
                </div>
              </template>
              <template v-slot:[`item.machineSpecs`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ item.machineSpecs }}
                </div>
              </template>
              <template v-slot:[`item.bookingDate`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertDateBooking(item.useStartDate, item.useEndDate, item.useStartTime, item.useEndTime) }}
                </div>
              </template>
              <template v-slot:[`item.dateBooking`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ floatFormat(item.dateBooking) }}日
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
              style="background: white; position: fixed; width: 100%; bottom: 0;"
            />
          </template>
        </TableLayout>
      </ValidationObserver>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import TabSelect from "@/components/forms/elements/TabSelect";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  FIELD_SELECT_TAB_REPORT_FIRST,
  FIELD_SELECT_TAB_REPORT_SECOND,
  FIELD_SELECT_TAB_REPORT_THREE,
} from "@/constants/FIELDS";

import {
  TAB,
  TITLE,
  TABLE_LABELS_RENTAL,
  TAB_RENTAL_RESULTS,
  SORT_ITEMS_BY_MACHINE
} from "@/constants/REPORT";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import {convertName, dateFormat, convertDateBooking, floatFormat, replaceDate} from "@/common/helper";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {format} from "date-fns";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Reports";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      TITLE,
      TABLE_LABELS_RENTAL,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      SORT_ITEMS_BY_MACHINE,
      TAB,
      FIELD_SELECT_TAB_REPORT_FIRST,
      FIELD_SELECT_TAB_REPORT_SECOND,
      FIELD_SELECT_TAB_REPORT_THREE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      formValues: {},

      companyItem: [],

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
        companyBranchId: null,
        start_use_field_date: "",
        end_use_field_date: "",
        machines_number_plate: "",
        agreements_first: [],
        agreements_second: [],
        agreements_three: [],
      },
  
      defaultOption: { id: "", name: "" },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      tab: TAB_RENTAL_RESULTS,
      flagUserRentalCompany: true,
      company_id: "",
      fieldId: "",
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    InputText,
    Select,
    Label,
    InputMonthpicker,
    TabSelect,
    SitePortalHeader,
    ValidationProvider,
    ValidationObserver
  },

  async mounted() {
    let today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    this.searchParams.start_use_field_date = yyyy + '/' + mm;
    this.searchParams.end_use_field_date = yyyy + '/' + mm;
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    this.getCompanies();
    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();
    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`Reports/getData`],
        Store.getters[`Reports/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items;

        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[0].last_page;
        searchParams.currentPage = data[0].current_page;
        searchParams.total_item = data[0].total;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.fieldId,
        company_id: this.searchParams.company_id,
        person_id: this.searchParams.person_id,
        machines_number_plate: this.searchParams.machines_number_plate,
        start_use_field_date: this.searchParams.start_use_field_date,
        end_use_field_date: this.searchParams.end_use_field_date,
        agreements_first: this.searchParams.agreements_first,
        agreements_second: this.searchParams.agreements_second,
        agreements_three: this.searchParams.agreements_three,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
  },
  created() {
    this.getFieldId();
    this.getCompanyId();
  },
  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
        await this.getItems();
        this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab7.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({name, value}, invalid) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
        this.getItems();
        this.selectedItems = [];
      }
    },
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/getRentalResultsByMachine`, {params: this.apiParams});
    },
    async getCompanies() {
      const data = await Store.dispatch(`Companies/getBookingInfoByFieldMachine`,
        {params: {field_id: this.fieldId}});
      this.companyItem = data.data?.contents?.entries?.map((item) => {
        return {
          id: item?.companyId,
          name: item?.companyName
        }
      });
      this.companyItem = [this.defaultOption, ...this.companyItem]
    },
    updateSelectedItems(value) {
      let currentItems = value.value
          ? this.items.filter(
              (element) => element.bookingId === value.item.bookingId
          )
          : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
            (item) => item.bookingId !== value.item.bookingId
        );
        this.selectedItems = this.selectedItems.filter(
            (item) => item.bookingId !== value.item.bookingId
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.id);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },
    convertDateBooking(start_date, end_date, start_time, end_time) {
      let startDate = replaceDate(start_date);
      let endDate = replaceDate(end_date);
      return convertDateBooking(startDate, endDate, start_time, end_time)
    },
    changeTab(id) {
      Store.dispatch(`${STORE}/setSelected`, id);
    },
    getCompanyId() {
      this.company_id = JSON.parse(sessionStorage.getItem("USER")).company_id;
    },
    getFieldId() {
      this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    },
    floatFormat(number) {
      return floatFormat(number);
    },
  
    async exportCsv() {
      const booking_ids = this.selectedItems.map((items) => items.bookingId);
      const searchParams = {...this.apiParams};
      searchParams.booking_ids = booking_ids;
      const response = await Store.dispatch(
        `${STORE}/exportBookingInfoByMachine`,
        searchParams
      );
      const {file, file_name} = response.data.contents;
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const url = window.URL.createObjectURL(new Blob([byteArray]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    },
    
    getStartMonthRules() {
      let fieldEndDate = this.searchParams?.end_use_field_date;
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01');
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let compareFieldStartDate = fieldEndDate ? "required|" + `compare-field-start-date:${fieldEndDate},期間（左）,期間（右）` : "required";
      return compareFieldStartDate;
    },
  
    getEndMonthRules() {
      let fieldStartDate = this.searchParams?.start_use_field_date;
      fieldStartDate = fieldStartDate + '/01';
      let compareFieldEndDate = "";
      if (fieldStartDate) {
        compareFieldEndDate = "required|" + `compare-field-end-date:${fieldStartDate},期間（右）,期間（左）`;
      }
      return compareFieldEndDate;
    },
  },
};
</script>
<style lang="scss" scoped>
  .last-item-tab {
    margin-left: -19px !important;
  }
  .last-item-tab {
    margin-left: -20px !important;
  }
  @media only screen and (width: 1024px) {
    .title-input-item {
      min-width: 95px !important;
    }
    .tabSelect {
      margin-top: 15px !important;
    }
    .last-item-tab {
      margin-left: -27px !important;
    }
  }
  
  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(1) {
    padding-right: 0;
  }
  ::v-deep .v-data-table--fixed-header > .v-data-table__wrapper > table .text-start:nth-child(2) {
    padding-left: 0;
  }
  .btn-on-search {
    margin-left: 15px;
  }

  @media (max-width: 1024px) {
    .SearchFormWrapper .labelWrapper {
      max-height: inherit !important;
      height: auto !important;
    } 
  }
</style>
