<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayout"
          >
            <template #tableHeader="{ updateHeader }">
              <v-row class="cst-row">
                <v-col cols="12" class="cst-col ml-4">
                  <TableHeader
                    ref="tableHeader"
                    :pageTitle="PAGE_TITLE"
                    :multiRemoveStatus="disableRemoveBtn"
                    :updateHeader="updateHeader"
                    :isClassify="false"
                    @openRemoveDialog="openRemoveDialog"
                    @openItemForm="openNewItemForm"
                  >
                    <SearchFormWrapper>
                      <Label label="種別" class="title-input-item tabSelect">
                        <TabSelect
                          name="company_type"
                          :items="selectTab"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="会社名" class="title-input-item">
                        <InputText
                          name="name"
                          :editable="true"
                          :values="searchParams"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="電話番号" class="title-input-item">
                        <InputText
                          name="phone"
                          :values="searchParams"
                          :editable="true"
                          @onInput="onChangeSearchParams"
                        />
                      </Label>
                      <Label label="所属ユーザー数" class="title-input-item" width="140px">
                        <InputText
                          name="number_member_from"
                          :values="searchParams"
                          :editable="true"
                          @onInput="onChangeSearchParams"
                          :validation_rules="getFrom()"
                        />
                      </Label>
                      <Label>
                        <v-subheader>
                          <span style="font-size: 25px">~</span>
                        </v-subheader>
                      </Label>
                      <Label label="所属ユーザー数" class="title-input-item" width="140px">
                        <InputText
                          name="number_member_to"
                          :values="searchParams"
                          :editable="true"
                          @onInput="onChangeSearchParams"
                          :validation_rules="getTo()"
                        />
                      </Label>
                      <v-spacer></v-spacer>
                      <Label>
                        <v-btn class="mr-4 search" color="primary" depressed @click="onSearch(invalid)">
                          検索
                        </v-btn>
                      </Label>
                    </SearchFormWrapper>
                  </TableHeader>
                </v-col>
              </v-row>
              <TableSortWrapper>
                <TableSort
                  :values="searchParams"
                  :sort_items="SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>
            </template>
            <template #tableBody="{ tableHeight }">
              <v-data-table
                item-key="id"
                v-model="selectedItems"
                :headers="TABLE_LABELS"
                :items="items"
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight : tableHeight - 44"
                fixed-header
                hide-default-footer
                disable-sort
                class="v-data-table__wrapper"
                sort-by="updatedAt"
                show-select
                :noDataText="NO_DATA"
                @item-selected="updateSelectedItems"
                @toggle-select-all="selectAllItems"
                @click:row="openItemForm"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ convertName(item?.name, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.company_type`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item?.company_type == 1 ? "建設会社" : "レンタル会社" }}
                  </div>
                </template>
                <template v-slot:[`item?.address`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      max-width: 470px;
                    "
                  >
                    {{ convertName(item.address, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.phone`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item?.user?.phone }}
                  </div>
                </template>
                <template v-slot:[`item.number_member`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item?.number_member }}人
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div
                    style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                    v-on:click.stop
                  >
                    <v-menu offset-y left :close-on-content-click="false">
                      <template v-slot:activator="{ on: menu }">
                        <v-tooltip >
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              depressed
                              style="background: none"
                              v-on="{ ...tooltip, ...menu }"
                              @click="listUserByCompany(item)"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </template>
                      <v-list>
                        <v-btn depressed class="btn-detail" @click="detailData">
                          所属ユーザー一覧を表示
                        </v-btn>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;"
              />
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <Company
        @formUpdate="formUpdate"
        :item="editedItem"
        :isNewItem="isNewItem"
        :actions="actions"
        @cancel="closeItemForm"
        @actionsLoading="actionsLoading"
        @reloadCompany="reloadCompany"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItemsRemoved"
        warning
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  LOADING_DATA,
  NO_DATA,
  MODE_EDIT,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";

import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import {
  COMPANIES_TABLE_LABELS,
  COMPANIES_SORT_ITEMS,
  COMPANIES_INITIAL_ITEM, TAB_COMPANIES,
} from "@/constants/COMPANIES"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_ADMIN } from "@/constants/GLOBALHEADER";

import _ from "lodash";
import {convertName, dateFormat} from "@/common/helper";
import InputNumber from "@/components/forms/elements/InputNumber";
import Company from "@/components/forms/company/components/Company";
import Loading from "@/components/loading/Loading";
import {ValidationProvider, ValidationObserver} from "vee-validate";

const PAGE_TITLE = "会社マスタ";

const PAGE_COUNT = 25;

const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

const SORT_ORDERS = TABLE_SORT_ORDERS;

const STORE = "Companies";

const TABLE_LABELS = COMPANIES_TABLE_LABELS;

const SORT_ITEMS = COMPANIES_SORT_ITEMS;

const INITIAL_ITEM = COMPANIES_INITIAL_ITEM;

const COMPANIES_MODE_EDIT = MODE_EDIT;

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA,
      LOADING_DATA,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      selectedItems: [],
      selectedItemsRemoved: [],
      editedItem: {},
      isNewItem: false,
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
      },
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      selectTab: TAB_COMPANIES,
      isLoading: false,
      actions: null,
    };
  },

  components: {
    Loading,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    InputText,
    Select,
    Label,
    TabSelect,
    InputNumber,
    Company,
    itemCompanyId: null,
    ValidationProvider,
    ValidationObserver
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_ADMIN.COMPANY.id,
    });

    this.getItems();

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((item) => ({
          ...item
        }));

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Register/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    apiParams() {
      return {
        company_type: this.searchParams.company_type,
        number_member_from: this.searchParams.number_member_from,
        number_member_to: this.searchParams.number_member_to,
        name: this.searchParams.name,
        phone: this.searchParams.phone,
        sort_value: this.searchParams.sort ?? 'name_kana',
        sort_by: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
  },

  methods: {
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
          await this.getItems();
          this.selectedItems = [];
      }
    },

    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayout.onChangeWindowSize()
    },

    onChangeSortParams({ name, value }, invalid) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
          this.getItems();
          this.selectedItems = [];
      }
    },

    openNewItemForm() {
      this.actions = "create"
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      this.actions = "edit"
      let params = { id: item.id}
      const siteInfo = await Store.dispatch(
        `${STORE}/getDetail`,
        {params}
      );
      // Show Detail Form
      if (siteInfo?.data?.contents?.entries) {
        this.editedItem = {...siteInfo?.data?.contents?.entries};
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    listUserByCompany(item) {
      this.itemCompanyId = item;
    },

    detailData() {
      this.$router.push({ name: 'listUserByCompany', query: { id: this.itemCompanyId.id}});
    },

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    formUpdate(params) {
      this.editedItem = { ...params };
    },

    async getItems() {
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },

    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });
      this.closeRemoveDialog();
      this.getItems();
      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        if (result?.data?.contents?.ids.length > 0) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
    },

    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.id === value.item.id
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      }
      else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.id !== value.item.id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.id !== value.item.id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.id);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },

    actionsLoading(status) {
      this.isLoading = status;
    },

    getFrom() {
      let fieldStartFrom = this.searchParams?.number_member_to;
      let compareFrom = 'digit';
      compareFrom += this.searchParams?.number_member_to ? `|compare-min:${fieldStartFrom},所属ユーザー数（左）,所属ユーザー数（右）` : "";
      return compareFrom;
    },

    getTo() {
      let fieldStartTo = this.searchParams?.number_member_from;
      let compareTo = "digit";
      if (this.searchParams?.number_member_from) {
        compareTo += `|compare-max:${fieldStartTo},所属ユーザー数（右）,所属ユーザー数（左）`;
      }
      return compareTo;
    },

    async reloadCompany() {
      if (this.actions === COMPANIES_MODE_EDIT) {
        let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
      }
      this.getItems();
    }
  },
};
</script>
<style scoped>
.title-input-item .label{
  color: #000000;
}
.btn-detail {
  background-color: #fff !important;
}
.btn-detail:hover:before {
  opacity: 0 !important;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0;
  width: calc(100% + 32px);
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:first-child {
  padding: 0 !important;
  width: 80px !important;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}
@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 

  .tabSelect {
    margin-top: 8px;
  }
}
</style>
