<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          ユーザー情報
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && !!item.active_flg && item.active_flg == 1"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable && (!!item.active_flg || item.active_flg === undefined)"
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onStopEditCF"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <UserForm
          :editable="editable"
          :detailData="item"
          :mainHeight="params.mainHeight"
          :company="company"
          :actions="actions"
          :isNewItem="isNewItem"
          :active_flg="item.active_flg"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="onStopEdit"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirm">
        <Confirm
          title="ユーザーを招待"
          :text1=inviteUsers
          text2=""
          @close="onClickBtnCancelShowConfirm"
          @yes="saveData"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
 
 <script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Confirm from "../popup/Confirm.vue";
import { Store } from "@/store/Store.js";
import UserForm from "./UserForm.vue";
import ConfirmActiveUser from "../../../../views/master/companies/listUserByCompany/components/ConfirmActiveUser.vue";
import _ from "lodash";

const FORMS = {
  BasicInfo: {
    id: 1,
    title: "基本情報",
  },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isShowConfirm: false,
      isClickCloseBtn: false,
      isShowQR: false,
      errors: {},
      isSubmitted: false,
      idSelect: null,
      isShowItemForm: false,
      dataRegister: null,
      keySearch: null,
      detailData: {},
      isShowDeactiveDialog: false,
      isShowActiveDialog: false,
      isHideButtonDeactive: false,
      inviteUsers: '',
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    Confirm,
    UserForm,
    ConfirmActiveUser,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    company: Object,
    actions: String,
  },

  computed: {
    getDataDetailUser() {
      return Store.getters[`Companies/getUser`];
    },
  },

  async mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.detailData = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    formUpdate(params) {
      this.dataRegister = params;
      this.$emit("formUpdate", params);
    },

    onEditable() {
      this.editable = true;
    },

    onStopEdit() {
      if (this.actions == 'create' || this.isClickCloseBtn) {
        this.$emit("cancel");
      } else {
        this.item = _.cloneDeep(this.detailData);
        this.editable = false;
        this.isShowConfirmDialog = false;
      }
    },

    onStopEditCF() {
      this.isShowConfirmDialog = true;
    },

    onSubmit() {
      if (this.detailData?.id) {
        this.saveData();
      } else {
        this.inviteUsers = `${this.detailData?.name_sei} ${this.detailData?.name_mei}さんを招待します。`;
        this.isShowConfirm = true;
      }
    },

    async saveData() {
      let res = {};
      if (this.isNewItem) {
        res = await Store.dispatch("Companies/addUserInfo", this.detailData);

      } else {
        res = await Store.dispatch("Companies/updateUserInfo", this.detailData);
      }

      if (!res.hasError && res.data.status_code === 200 && this.isNewItem) {
        this.$emit("cancel");
        this.$emit("getListUser");
        if (this.isNewItem) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: res?.data?.message,
          },);
        }
      } else if (!res.hasError && res.data.status_code === 200 && !this.isNewItem) {
        this.$emit("getListUser");
        this.editable = false;
        this.$emit("reloadItem");
        Store.dispatch("Toast/show", {
          status: 200,
          message: res?.data?.message,
        });
      } else {
        this.onClickBtnCancelShowConfirm();
      }
    },

    updateValidate(params) {
      const { valid, errors } = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    closeShowQrCode() {
      this.isShowQR = false;
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelShowConfirm() {
      this.isShowConfirm = false;
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },
  },
};
</script>
 <style lang="scss" scoped>
.title-popup {
  color: #274fc2;
}

.popup-company {
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
