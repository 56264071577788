<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading" />
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <v-row>
              <v-col>
                <TableHeader
                  ref="tableHeader"
                  :pageTitle="TITLE"
                  :isDelete="false"
                  :isAddNew="false"
                  :isClassify="false"
                  :updateHeader="updateHeader"
                >
                  <SearchFormWrapper>
                    <!-- 文字入力 -->
                    <Label label="ナンバー" class="title-input-item">
                      <InputText
                        name="number_plate"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-6 mt-10"
                      color="primary"
                      depressed
                      @click="onSearch"
                    >
                      検索
                    </v-btn>
                  </SearchFormWrapper>
                </TableHeader>
              </v-col>
            </v-row>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                :isShow="false"
                @onInput="onChangeSortParams"
                class="ml-3 sort_report"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                <v-tab 
                  v-for="tab in TAB" 
                  :key="tab.id" 
                  @click="changeTab(tab.id)" 
                  color="primary" 
                  class="tab-machine primary--text"
                  >
                  {{tab.name}}
                </v-tab>
              </v-tabs>
            </template>
            <v-data-table
              item-key="field_machine_id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 90 : tableHeight - 115"
              fixed-header
              hide-default-footer
              disable-sort
              show-select
              class="v-data-table__wrapper"
              sort-by="updatedAt"
              :noDataText="NO_DATA_MESSAGE"
              @item-selected="updateSelectedItems"
              @toggle-select-all="selectAllItems"
              @update:searchParams.currentPage="$vuetify.goTo(0)"
              :key=searchParams.currentPage
            >
              <template v-slot:top>
                  <v-btn
                    class="ml-4 mt-2 mb-2"
                    color="primary"
                    left
                    depressed
                    :disabled="!selectedItems.length"
                    @click="downloadInspectionYearsByMachineIds()"
                  >
                    ダウンロード
                  </v-btn>
              </template>
              <template v-slot:[`item.number_plate`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ item.number_plate }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSortCustom";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {  TAB,
          TITLE,
          TABLE_LABELS,
          TAB_ANNUA } from "@/constants/REPORT";
import CrudForm from "@/components/forms/crud/CrudForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Loading from "@/components/loading/Loading";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Reports";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      TITLE,
      TABLE_LABELS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TAB,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
      },
      tab: TAB_ANNUA,
      flagUserRentalCompany: true,

      fieldId: "",
      dialog: null,
      selectedItems: [],
      isLoading: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    CrudForm,
    InputText,
    Select,
    Label,
    InputDatepicker,
    SitePortalHeader,
    Loading,
  },
  created() {
    this.getFieldId();
  },
  async mounted() {
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items;
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[0].last_page;
        searchParams.currentPage = data[0].current_page;
        searchParams.total_item = data[0].total;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * API Param
     */
    apiParams() {
      return {
        number_plate: this.searchParams.number_plate,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        field_id: this.fieldId,
      };
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch() {
      this.searchParams["currentPage"] = 1;
      await this.getItems();
      this.selectedItems = [];
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
      this.selectedItems = [];
    },
    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      let params = { ...this.apiParams, id: this.$route.query.id };
      await Store.dispatch(`${STORE}/getListMachineHasInspectionYear`, {
        params: params,
      });
    },
    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) =>
              element.field_machine_id === value.item.field_machine_id
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.field_machine_id !== value.item.field_machine_id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.field_machine_id !== value.item.field_machine_id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.field_machine_id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.field_machine_id);
          }
        });
      }
    },
    changeTab(id) {
      Store.dispatch(`${STORE}/setSelected`, id);
    },

    getFieldId() {
      this.fieldId = JSON.parse(
        sessionStorage.getItem("CURRENT_SITE")
      ).field_id;
    },

    async downloadInspectionYearsByMachineIds() {
      let ids = Array.from(
        new Set(this.selectedItems.map((obj) => obj.field_machine_id))
      );
      const response = await Store.dispatch(
        `${STORE}/downloadInspectionYearsByMachineIds`,
        {
          ids,
        }
      );
      let filename = response?.data?.contents?.entries?.file_name;
      let file = response?.data?.contents?.entries?.file;
      let element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;base64," + file);
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
<style scoped>
  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(2) {
    padding-right: 0;
  }

  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(3) {
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    .SearchFormWrapper .labelWrapper {
      max-height: inherit !important;
      height: auto !important;
    } 
  }
</style>
