import Vue from "vue";
import Vuex from "vuex";
import {masterUsers} from "@/api/modules/masterUsers";

Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = masterUsers;
export const MasterUsers = {
  namespaced: true,

  state: {
    data_user_active: {},
    data_user:{},
    data: [],
    leaderIds: [],
    dataAll: [],
    fieldGroupNotApprove: [],
    listUserByCompany: [],
    pagination: {},
    paginationNotApprove: {},
    isLoading: {},
    roleLogin: 0,
    countFieldGroupNotApprove: 0,
  },

  mutations: {
    SET_USER_ACTIVE(state, payload) {
      state.data_user_active = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_USER(state, payload) {
      state.data_user = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_LEADER_IDS(state, payload) {
      state.leaderIds = payload;
    },
    SET_DATA_All(state, payload) {
      state.dataAll = payload;
    },
    SET_FIELD_USER_NOT_APPROVE(state, payload) {
      state.fieldGroupNotApprove = payload;
    },
    SET_COUNT_FIELD_USER_NOT_APPROVE(state, payload) {
      state.countFieldGroupNotApprove = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_PAGINATION_NOT_APPROVE(state, payload) {
      state.paginationNotApprove = payload;
    },
    SET_FIELD_GROUP(state, payload) {
      state.fieldGroup = payload;
    },
    SET_ROLE_LOGIN(state, payload) {
      state.roleLogin = payload;
    },
  },
  actions: {
    async getActiveUser({commit}, payload) {
      let params = {};
      if (payload?.keyword) params.namesearch = payload.keyword;
      if (payload?.company_id) params.company_id = payload.company_id;
      const response = await ENTITY.getActiveUser(params);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_USER_ACTIVE", entries);
      return response;
    },
    async getUserInfoById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getUserInfoById(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_USER", entries);
      return response;
    },
    async getListAllUserInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListAllUserInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getListFieldGroupInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldGroupInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getListFieldGroupInfoAll({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldGroupInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_DATA_All", entries);
      return response;
    },
    async getListFieldGroupNotApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldGroupNotApprove(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_FIELD_USER_NOT_APPROVE", entries);
      commit("SET_PAGINATION_NOT_APPROVE", pagination);
      return response;
    },

    async getCountFieldGroupNotApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getCountFieldGroupNotApprove(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {count} = response.data.contents;
      commit("SET_COUNT_FIELD_USER_NOT_APPROVE", count);
      return response;
    },

    async bulkAddFieldGroupInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.bulkAddFieldGroupInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async selectUseableFieldGroup({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.selectUseableFieldGroup(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async addFieldGroupInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addFieldGroupInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteFieldGroupByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteFieldGroupByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getDetail({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getDetail(payload);
      const entries = response?.data?.contents.entries;
      commit("SET_IS_LOADING", false);
      commit("SET_FIELD_GROUP", entries);
      return response;
    },

    async setStatusApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.setStatusApprove(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async setStatusReceiptAllocation({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.setStatusReceiptAllocation(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteFieldGroupRegisterByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteFieldGroupRegisterByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async approveFieldGroupByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.approveFieldGroupByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getFieldGroupInfoByEmail({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getFieldGroupInfoByEmail(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async updateFieldGroupInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateFieldGroupInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getListPartnerLeaderInField({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListPartnerLeaderInField(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_LEADER_IDS", entries);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getLeaderIds: (state) => {
      return state.leaderIds;
    },

    getAllFieldGroupInfoData: (state) => {
      return state.dataAll;
    },

    getDataNotApprove: (state) => {
      return state.fieldGroupNotApprove;
    },

    getCountFieldGroupNotApprove: (state) => {
      return state.countFieldGroupNotApprove;
    },

    getPagination: (state) => {
      return state.pagination;
    },

    getPaginationNotApprove: (state) => {
      return state.paginationNotApprove;
    },

    getCompany: (state) => {
      return state.company;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getUserActive: (state) => {
      return state.data_user_active;
    },
    getUser: (state) => {
      return state.data_user;
    },
  },
};
