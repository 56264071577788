import Api from "../api";

const SITES_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const SITES_BASE_URL = `${SITES_URL}/field_group_users`;
const GET_LIST_FIELD_GROUP_INFO = `${SITES_BASE_URL}/get_list_field_group_info`;
const GET_LIST_FIELD_GROUP_USERS = `${SITES_BASE_URL}/get_list_field_group_user_info`;
const GET_COUNT_FIELD_USER_NOT_APPROVE = `${SITES_BASE_URL}/get_count_field_user_not_approve`;
const GET_FIELD_USER_INFO_BY_EMAIL = `${SITES_BASE_URL}/get_field_user_info_by_email`;
const DELETE_FIELD_USER_REGISTER_BY_IDS = `${SITES_BASE_URL}/delete_field_user_register_by_ids`;
const DELETE_FIELD_USER_BY_IDS = `${SITES_BASE_URL}/delete_field_user_by_ids`;
const GET_DETAIL_SITE_URL = `${SITES_BASE_URL}/get_field_group_user_by_id`;
const SET_STATUS_APPROVE = `${SITES_BASE_URL}/set_sts_approve`;
const SET_STATUS_RECEIPT_ALLOCATION = `${SITES_BASE_URL}/set_status_receipt_allocation`;
const BULK_ADD_FIELD_GROUP_INFO = `${SITES_BASE_URL}/bulk_add_field_group_info`;
const ADD_FIELD_USER_INFO = `${SITES_BASE_URL}/add_field_user_info`;
const APPROVE_FIELD_USER_BY_IDS = `${SITES_BASE_URL}/approve_field_user_by_ids`;
const BULK_UPDATE_FIELD_GROUP_USERS = `${SITES_BASE_URL}/bulk_update_field_group_users_info`;
const UPDATE_FIELD_GROUP_USER_INFO = `${SITES_BASE_URL}/update_field_group_user_info`;
const GET_LIST_PARTNER_LEADER_IN_FIELD = `${SITES_URL}/fields/get_list_partner_leader_in_field`;

export const fieldGroupUsers = {
  getListFieldGroupInfo: (params) => {
    return Api.get(`${GET_LIST_FIELD_GROUP_INFO}`, params);
  },

  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}`, params);
  },

  getListFieldGroupUsers: (params) => {
    return Api.get(`${GET_LIST_FIELD_GROUP_USERS}`, params);
  },

  getCountFieldUserNotApprove: (params) => {
    return Api.get(`${GET_COUNT_FIELD_USER_NOT_APPROVE}`, params);
  },

  getFieldUserInfoByEmail: (params) => {
    return Api.get(`${GET_FIELD_USER_INFO_BY_EMAIL}`, params);
  },

  deleteFieldUserByIds: (params) => {
    return Api.post(DELETE_FIELD_USER_BY_IDS, params);
  },

  setStatusApprove: (params) => {
    return Api.post(SET_STATUS_APPROVE, params);
  },

  setStatusReceiptAllocation: (params) => {
    return Api.post(SET_STATUS_RECEIPT_ALLOCATION, params);
  },

  deleteFieldUserRegisterByIds: (params) => {
    return Api.post(DELETE_FIELD_USER_REGISTER_BY_IDS, params);
  },

  bulkAddFieldGroupInfo: (params) => {
    return Api.post(BULK_ADD_FIELD_GROUP_INFO, params, {headers: {'Content-Type': 'multipart/form-data'}});
  },

  addFieldUserInfo: (params) => {
    return Api.post(ADD_FIELD_USER_INFO, params);
  },

  approveFieldUserByIds: (params) => {
    return Api.post(APPROVE_FIELD_USER_BY_IDS, params);
  },

  bulkUpdateFieldGroupUsers: (params) => {
    return Api.post(BULK_UPDATE_FIELD_GROUP_USERS, params);
  },
  updateFieldGroupUserInfo: (params) => {
    return Api.post(UPDATE_FIELD_GROUP_USER_INFO, params);
  },
  getListPartnerLeaderInField: (params) => {
    return Api.get(GET_LIST_PARTNER_LEADER_IN_FIELD, params);
  },
}
