<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <template v-if="editable && (this.admin_flg || !this.user.company?.id)" class="select_user">
                            <v-col cols="10">
                              <Label label="会社" :editable="editable" required>
                                <template class="select_user">
                                  <v-text-field
                                    v-model="getNameCompany"
                                    dense
                                    disabled
                                    :filled="editable"
                                    validation_label="会社名"
                                    validation_rules="required"
                                  ></v-text-field>
                                </template>
                              </Label>
                            </v-col>
                            <v-col cols="2">
                              <Label label=" " :editable="editable">
                                <v-btn
                                  style="margin-top: -5px"
                                  color="primary"
                                  @click="openSearchDialogCompany"
                                >検索
                                </v-btn>
                              </Label>
                            </v-col>
                          </template>
                          <template v-else>
                            <v-col cols="12">
                              <Label label="会社" :editable="editable" required>
                                <template class="select_user">
                                  <v-text-field
                                    v-model="getNameCompany"
                                    dense
                                    disabled
                                    :filled="editable"
                                    class="name manager"
                                  ></v-text-field>
                                </template>
                              </Label>
                            </v-col>
                          </template>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="現場名" :editable="editable" required>
                          <InputText
                            name="name"
                            :values="formValues.fields"
                            :editable="editable"
                            placeholder=""
                            validation_label="現場名"
                            validation_rules="required|max:255"
                            @onInput="onInput('fields', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-row v-if="editable">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="着工予定年月" :editable="editable" required>
                              <InputMonthpicker
                                name="field_start_date"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                validation_label="着工予定年月"
                                :validation_rules="getStartMonthRules()"
                                @onInput="onInput('fields', $event)"
                                :flagNull="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="竣工予定年月" :editable="editable" required>
                              <InputMonthpicker
                                name="field_end_date"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                validation_label="竣工予定年月"
                                :validation_rules="getEndMonthRules()"
                                :flagNull="true"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row v-if="editable">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="利用開始予定日" :editable="editable" required>
                              <InputDatepicker
                                name="start_use_field_date"
                                :editable="editable"
                                :values="formValues.fields"
                                validation_label="利用開始予定日"
                                :validation_rules="getStartDateRules()"
                                :flagNull="true"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="利用終了予定日" :editable="editable" required>
                              <InputDatepicker
                                name="end_use_field_date"
                                :editable="editable"
                                :values="formValues.fields"
                                validation_label="利用終了予定日"
                                :validation_rules="getEndDateRules()"
                                :flagNull="true"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row v-if="!editable">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="工期">
                              <v-text-field
                                v-model="betweenfieldDate"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="利用期間">
                              <v-text-field
                                v-model="betweenfieldUserDate"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              ></v-text-field>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="現場代表電話番号" :editable="editable">
                              <InputText
                                name="phone"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                validation_label="現場代表電話番号"
                                validation_rules="max:16|phone"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      
                      <v-col v-if="!isNewItem && (USER.admin_flg == ADMIN_USER || item.fields.active_flg != ACTIVE_FLG_NONE)"> 
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="現場コード" :editable="editable">
                              <InputText
                                name="field_code"
                                :values="formValues.fields"
                                :editable="false"
                                placeholder=""
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="郵便番号" :editable="editable" required>
                              <InputText
                                name="postal_code"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                validation_label="郵便番号"
                                validation_rules="required|postcode"
                                @onInput="onChangePostalcode"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="都道府県" :editable="editable" required>
                              <Select
                                name="prefecture_id"
                                :values="formValues.fields"
                                :items="prefecture"
                                item_text="prefecture"
                                :editable="editable"
                                validation_label="都道府県"
                                validation_rules="required"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="市区町村" :editable="editable" required>
                          <InputText
                            name="city"
                            :values="formValues.fields"
                            :editable="editable"
                            placeholder=""
                            validation_label="市区町村"
                            validation_rules="required"
                            @onInput="onInput('fields', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="番地・建物名" :editable="editable">
                        <InputText
                          name="address_detail"
                          :values="formValues.fields"
                          :editable="editable"
                          placeholder=""
                          @onInput="onInput('fields', $event)"
                        />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="システム利用申込者（第一責任者）" :editable="editable"></Label>
                        <v-row>
                          <v-col cols="4" sm="9" md="9">
                            <Label label="氏名" :editable="editable" required>
                              <template v-if="editable && this.admin_flg" class="select_user">
                                <v-text-field
                                  v-model="getFullNameManager"
                                  dense
                                  disabled
                                  class="name manager"
                                  v-if="getFullNameManager"
                                ></v-text-field>
                                <v-text-field
                                  v-else
                                  v-model="showUserNameNotEdit"
                                  dense
                                  disabled
                                  class="name"
                                ></v-text-field>
                              </template>
                              <v-text-field v-if="!editable"
                                v-model="showUserNameNotEdit"
                                dense
                                color="primary"
                                :disabled="!editable"
                                :filled="editable"
                              >
                              </v-text-field>
                              <v-text-field v-if="!this.admin_flg && editable"
                                v-model="managerNameNotAdmin"
                                dense
                                color="primary"
                                :disabled="true"
                                :filled="editable"
                              >
                              </v-text-field>
                            </Label>
                          </v-col>
                          <v-col cols="8" sm="3" md="3" v-if="editable && this.admin_flg">
                            <Label label=" " :editable="editable">
                              <v-btn
                                style="margin-top: -5px"
                                color="primary"
                                @click="openSearchDialogUser"
                              >検索
                              </v-btn>
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6">
                            <Label label="現場管理コード" :editable="editable">
                              <InputText
                                name="field_management_code"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                validation_label="現場管理コード"
                                validation_rules="max:16"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <!--<v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6"> 
                            <Label label="現場利用申請の承認" class="title-input-item" :editable="editable">
                              <TabSelect
                                :values="approvalTabData"
                                :items="APPROVAL_TAB"
                                :editable="editable"
                                name="approval_tab"
                                @onInput="onInput('fields', $event)"
                              />
                              <div class="v-messages error--text" v-if="editable && approvalTabData.approval_tab.length == 0">どちらかを選択してください。</div>
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="isShowApprovalFrequency">
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6"> 
                            <Label label="自動承認間隔" :editable="editable">
                              <Select
                                name="approval_frequency"
                                :values="formValues.fields"
                                :items="APPROVAL_FREQUENCY"
                                :editable="editable"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item>
                      <v-row>
                        <v-col cols="4" sm="9" md="9">
                          <SwitchInput
                            name="allowed_cancel_booking"
                            :label="`点検実績のある予約キャンセルを許可する`"
                            :values="formValues.fields"
                            :editable="editable"
                            @onInput="onInput('fields', $event)"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item>
                      <v-row>
                        <v-col cols="4" sm="9" md="9">
                          <SwitchInput
                              name="check_location"
                              :label="`EPSデバイスを利用するので位置情報チェックをする`"
                              :values="formValues.fields"
                              :editable="editable"
                              @onInput="onInput('fields', $event)"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
    <Popup width="500px" :dialog="isShowFormSearchCompany">
      <CompanySelectDialog
        title="会社選択"
        :formCompanyActiveValues="formCompanyActiveValues"
        :countData="countData"
        @close="onClickBtnCancelForm"
        @formUpdateCompany="formUpdateCompany"
        @updateKeyWord="updateKeyWord"
        warning
        @onSearch="searchCompanyActive"
      />
    </Popup>
    <Popup width="500px" :dialog="isShowFormSearchUser">
      <UserSelectDialog
        title="ユーザー選択"
        :formUserActiveValues="formUserActiveValues"
        :countData="countData"
        @close="onClickBtnCancelForm"
        @formUpdateManager="formUpdateManager"
        @updateKeyWord="updateKeyWord"
        warning
        @onSearch="searchUserActive"
      />
    </Popup>
  </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import {Store} from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import {CRUD_TITLE} from "@/constants/CRUDS";
import FileInput from "@/components/forms/elements/FileInput";
import CompanySelectDialog from "./components/CompanySelectDialog";
import UserSelectDialog from "./components/UserSelectDialog";
import Popup from "@/components/common/Popup.vue";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import {format} from "date-fns";
import {ERR_NO_INPUT} from "@/constants/COMMON";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import TabSelect from "@/components/forms/elements/TabSelect";
import {
  FIELD_APPROVAL_TAB,
  FIELD_APPROVAL_FREQUENCY,
} from "@/constants/FIELDS"; //絞り込みフォームで使用
import SwitchInput from "@/components/forms/elements/SwitchInput";

const ADMIN_USER = 1;
const ACTIVE_FLG_NONE = 0;
const APPROVAL_TAB = FIELD_APPROVAL_TAB;
const APPROVAL_FREQUENCY = FIELD_APPROVAL_FREQUENCY;

export default {
  data: () => {
    return {
      formValues: {},
      description: {},
      company_branches: [],
      companyBuilding: [],
      prefecture: [],
      resetValue: {},
      CRUD_TITLE,
      isShowFormSearchCompany: false,
      isShowFormSearchUser: false,
      formCompanyActiveValues: {
        items: [],
        keyword: "",
        selected: null,
      },
      formUserActiveValues: {
        items: [],
        keyword: "",
        selected: null,
      },
      nameCompany: "",
      fullNameManager: "",
      keyword: "",
      betweenfieldDate: "",
      betweenfieldUserDate: "",
      fieldValues: {},
      showUserNameNotEdit: "",
      ownerCompanyNotAdmin: "",
      managerNameNotAdmin: "",
      countData: 0,
      ADMIN_USER,
      ACTIVE_FLG_NONE,
      APPROVAL_TAB,
      APPROVAL_FREQUENCY,
      approvalTabData: {},
      isShowApprovalFrequency: false,
      approvalFrequencyData: {},
      //APPROVAL_TAB & APPROVAL_FREQUENCY のデフォルト値を作成する
      isFirstVisit: true,
      user: JSON.parse(sessionStorage.getItem("USER")).Login.user,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    CompanySelectDialog,
    UserSelectDialog,
    Popup,
    InputMonthpicker,
    TabSelect,
    SwitchInput
  },
  props: {
    item: Object,
    companyBranches: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
    admin_flg: Number,
  },
  mounted() {

    this.$watch(
      () => [this.item],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`Users/getUserActive`],
      (data) => {
        const getListUserActive = {
          ...data,
        };
        this.formUserActiveValues.items = getListUserActive;
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`Companies/getDataBuilding`],
      (data) => {
        this.companyBuilding = data;
        const getListCompanyActive = {
          ...data,
        };
        this.formCompanyActiveValues.items = getListCompanyActive;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`Prefectures/getData`],
      (data) => {
        this.prefecture = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    },
    USER() {
      return JSON.parse(sessionStorage.getItem("USER")).Login.user;
    },
    getNameCompany() {
      return this.nameCompany;
    },
    getFullNameManager() {
      return this.fullNameManager;
    }
  },
  methods: {
    initData(data) {
      const formValues = data[0];
      this.formValues = {...formValues};
      this.fieldValues = formValues.fields;
      if (this.fieldValues['manager_user_name']) {
        this.showUserNameNotEdit = this.fieldValues['manager_user_name'];
        this.nameCompany = this.fieldValues['company_name'];
      } else {
        this.showUserNameNotEdit = "";
      }
      this.initDataTime(this.fieldValues);

      if (!this.admin_flg) {
        const user = JSON.parse(sessionStorage.getItem("USER")).Login.user;
        const formValuesOther = {...this.formValues};
        if (user?.company?.name && this.isNewItem) {
          formValuesOther['fields']['owner_company_id'] = this.formValues?.fields?.owner_company_id ? this.formValues?.fields?.owner_company_id : user?.company?.id;
          this.ownerCompanyNotAdmin = user?.company?.name;
          this.nameCompany = this.formValues?.fields?.company_name ? this.formValues?.fields?.company_name : user?.company?.name;
        } else if (this.formValues?.fields?.company_name) {
          this.ownerCompanyNotAdmin = this.formValues?.fields?.company_name;
          this.nameCompany = this.formValues?.fields?.company_name;
        } else {
          this.ownerCompanyNotAdmin = "";
        }
        if (user && this.isNewItem) {
          this.managerNameNotAdmin = user.name_sei + '　' + user.name_mei;
          formValuesOther['fields']['manager_user_id'] = user?.id
        } else if (this.fieldValues['manager_user_name']) {
          this.managerNameNotAdmin = this.fieldValues['manager_user_name'];
        } else {
          this.managerNameNotAdmin = "";
        }
        this.formValues = formValuesOther;
      }

      this.approvalTabData =  this.getApprovalTabData(formValues.fields);
    },
    onInput(parent_name, {name, value}) {
      const formValues = {...this.formValues};
      if (this.admin_flg && name === 'owner_company_id' && formValues['fields']['owner_company_id'] !== value) {
        this.fullNameManager = "";
        this.showUserNameNotEdit = "";
        delete formValues['fields']['manager_user_id'];
        delete formValues['fields']['manager_user_name'];
      }
      if(!this.formValues.fields) {
        return;
      }
      // if(name === 'approval_tab') {
      //     if(value.includes(0)) {
      //       formValues['fields']['approval_manual'] = 1;
      //     } else {
      //       formValues['fields']['approval_manual'] = 0;
      //     }
      //     if(value.includes(1)) {
      //       formValues['fields']['approval_auto'] = 1;
      //       this.isShowApprovalFrequency = true;
      //     } else {
      //       formValues['fields']['approval_auto'] = 0;
      //       this.isShowApprovalFrequency = false;
      //     }
      // }
      if (parent_name) {
        formValues[parent_name][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    getStartDateRules() {
      let fieldStartDate = this.formValues?.fields?.field_start_date;
      let fieldEndDate = this.formValues?.fields?.field_end_date;
      fieldStartDate = fieldStartDate + '/01';
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01')
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let endUseFieldDate = `${String(this.formValues?.fields?.end_use_field_date).replaceAll(
        "-",
        "/"
      )}`;
      let outOfRange = "";
      let compareFieldEndDateUser = "";
      if (this.formValues?.fields?.field_start_date && this.formValues?.fields?.field_end_date){
        outOfRange =  `err-out-of-range:${fieldStartDate},${fieldEndDate},利用開始予定日,着工予定年月,竣工予定年月` + "|";
      }
      if (this.formValues?.fields?.start_use_field_date) {
        compareFieldEndDateUser = `compare-field-end-date-use:${endUseFieldDate}`;
      }
      return "required|" + outOfRange + compareFieldEndDateUser;
    },
    getEndDateRules() {
      let fieldStartDate = this.formValues?.fields?.field_start_date;
      let fieldEndDate = this.formValues?.fields?.field_end_date;
      fieldStartDate = fieldStartDate+'/01';
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate+'/01')
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let startUseFieldDate = `${String(this.formValues?.fields?.start_use_field_date).replaceAll(
        "-",
        "/"
      )}`;
      let outOfRange = "";
      let compareFieldStartDateUser = "";
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let errDatePast = "";
      let isMonth = false;
      if (this.formValues?.fields?.field_start_date && this.formValues?.fields?.field_end_date){
        outOfRange = `err-out-of-range:${fieldStartDate},${fieldEndDate},利用終了予定日,着工予定年月,竣工予定年月` + "|";
      }
      if (this.formValues?.fields?.start_use_field_date) {
        compareFieldStartDateUser = `compare-field-start-date-use:${startUseFieldDate}`;
      }
      if (this.formValues?.fields?.end_use_field_date) {
        errDatePast = `err-date-past:${now},${isMonth},利用終了予定日` + "|";
      }
      return "required|" + errDatePast + outOfRange + compareFieldStartDateUser;
    },

    getStartMonthRules() {
      let fieldEndDate = this.formValues?.fields?.field_end_date;
      let startUseFieldDate = this.formValues?.fields?.start_use_field_date;
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01');
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }

      let errDateGreaterThan = startUseFieldDate ? `err-month-greater-range:${startUseFieldDate},着工予定年月,利用開始予定日` : "";

      let compareFieldStartDate = fieldEndDate ? `compare-field-start-date:${fieldEndDate},着工予定年月,竣工予定年月` + "|" : "";
      return "required|" + compareFieldStartDate + errDateGreaterThan;
    },

    getEndMonthRules() {
      let fieldStartDate = this.formValues?.fields?.field_start_date;
      let endtUseFieldDate = this.formValues?.fields?.end_use_field_date; 
      fieldStartDate = fieldStartDate + '/01';
      let compareFieldEndDate = "";
      let isMonth = true;
      let now = new Date();
      let firstdayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      firstdayOfMonth = format(firstdayOfMonth, "yyyy/MM/dd");
      let errMonthPast = firstdayOfMonth ? `err-month-past:${firstdayOfMonth},${isMonth},竣工予定年月` + "|" : "";
      if (this.formValues?.fields?.field_start_date) {
        compareFieldEndDate = `compare-field-end-date:${fieldStartDate},竣工予定年月,着工予定年月` + "|";
      }
      let errDateGreaterThan = endtUseFieldDate ? `err-end-date-greater-range:${endtUseFieldDate},竣工予定年月,利用終了予定日` : "";
      return "required|" + errMonthPast + compareFieldEndDate + errDateGreaterThan;
    },

    async openSearchDialogCompany() {
      let result = await Store.dispatch("Companies/getDataBuilding", {
        keyword: "",
      });
      let data = result?.data?.contents?.entries;
      this.countData = data.length;
      this.isShowFormSearchCompany = true;
    },

    async openSearchDialogUser() {
      if (!this.formValues?.fields?.owner_company_id) {
        Store.dispatch("Error/show", {
          status: 200,
          message: ERR_NO_INPUT.replaceAll("{%1}", "会社名"),
        });
        return;
      }
      let result = await Store.dispatch("Users/getActiveUser", {
        keyword: "",
        company_id: this.formValues?.fields?.owner_company_id,
      });
      let data = result?.data?.contents?.entries;
      this.countData = data.length;
      this.isShowFormSearchUser = true;
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePhone({value}) {
      const formValues = {...this.formValues};
      formValues.phone = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    onClickBtnCancelForm() {
      this.isShowFormSearchCompany = false;
      this.isShowFormSearchUser = false;
    },

    formUpdateCompany(values) {
      this.formCompanyActiveValues = {...values};
      if (this.formCompanyActiveValues.selected) {
        let arr = Object.values(this.formCompanyActiveValues.items);
        arr = arr.filter((e) => {
          return e.id == this.formCompanyActiveValues.selected;
        });
        this.nameCompany = arr[0].name;
        const formValues = {...this.formValues};
        formValues['fields']['owner_company_id'] = arr[0].id;
        formValues['fields']['company_name'] = arr[0].name;
        this.fullNameManager = "";
        this.showUserNameNotEdit = "";
        if (this.isNewItem) {
          delete formValues['fields']['manager_user_id'];
          delete formValues['fields']['manager_user_name'];
        }
        this.formValues = formValues;
        this.$emit("formUpdate", formValues);
        this.isShowFormSearchCompany = false;
        this.isShowFormSearchUser = false;
        const formCompanyActiveValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formCompanyActiveValues = formCompanyActiveValues;
      }
    },

    formUpdateManager(values) {
      this.formUserActiveValues = {...values};
      if (this.formUserActiveValues.selected) {
        let arr = Object.values(this.formUserActiveValues.items);
        arr = arr.filter((e) => {
          return e.id == this.formUserActiveValues.selected;
        });
        this.fullNameManager = arr[0].name_sei + '　' + arr[0].name_mei;
        const formValues = {...this.formValues};
        formValues['fields']['manager_user_id'] = arr[0].id;
        this.formValues = formValues;
        this.$emit("formUpdate", formValues);
        this.isShowFormSearchCompany = false;
        this.isShowFormSearchUser = false;
        const formUserActiveValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formUserActiveValues = formUserActiveValues;
      }
    },

    updateKeyWord(values) {
      this.keyword = values;
    },

    async searchCompanyActive() {
      const {keyword} = this.keyword;
      await Store.dispatch("Companies/getDataBuilding", {
        keyword,
      });
    },

    async searchUserActive() {
      const {keyword} = this.keyword;
      await Store.dispatch("Users/getActiveUser", {
        keyword,
        company_id: this.formValues?.fields?.owner_company_id,
      });
    },

    initDataTime(formValues) {
      const startFieldDate = formValues["field_start_date"];
      const endFieldDate = formValues["field_end_date"];
      const startFieldUseDate = formValues["start_use_field_date"];
      const endFieldUseDate = formValues["end_use_field_date"];

      if (startFieldDate || endFieldDate) {
        this.betweenfieldDate = `${startFieldDate}〜${endFieldDate}`;
      } else {
        this.betweenfieldDate = "";
      }

      if (startFieldUseDate || endFieldUseDate) {
        this.betweenfieldUserDate = `${startFieldUseDate}〜${endFieldUseDate}`;
      } else {
        this.betweenfieldUserDate = "";
      }
    },
        /**
     * 郵便番号から都道府県、市町村名を検索
     */
     async onChangePostalcode({ value }) {
      const formValues = { ...this.formValues };
      formValues.fields.postal_code = value;
      if (value == "") {
        formValues.fields.prefecture_id = null;
        formValues.fields.city = '';
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if(result){
          formValues.fields.prefecture_id = result.prefecture.id;
          formValues.fields.city = result.city;
        }
      }

      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    getApprovalTabData(fields) {
      const { approval_manual, approval_auto, id} = fields;
      const approval_tab = [];
      if (approval_manual === 1 || (!id && this.isFirstVisit)) {
        approval_tab.push(0);
      }
      if (approval_auto === 1) {
        approval_tab.push(1);
      }
      this.isFirstVisit = false;
      return { approval_tab };
    },

    // getApprovalFrequencyData() {
    //   if(!this.formValues.fields) {
    //     return {approval_frequency:{ id: "0.50", name: "30分ごと"}};
    //   } 
    //   return this.formValues.fields;
    // }

  },
};
</script>
<style lang="scss" scoped>
.select_user {
  max-width: 30vw;
  height: unset;
  min-height: 40px !important;
  width: 100%;
  background-color: #f0f0f0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

div::v-deep .select_user .v-btn__content {
  width: 100%;
}

.name.manager {
  width: auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  text-transform: capitalize;
  letter-spacing: 0;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .select_user {
    max-width: 26vw !important;
  }
}

@media (max-width: 1024px) {
  .select_user {
    max-width: 27vw !important;
  }
}

::v-deep .theme--light.v-label {
  color: black;
}
</style>
