import Api from "../api";

const SITES_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const SITES_BASE_URL = `${SITES_URL}/users`;
const SITES_BASE_URL2 = `${SITES_URL}/field_user`;
const SITES_BASE_URL3 = `${SITES_URL}/companies`;
const GET_LIST_ALL_USER_INFO = `${SITES_BASE_URL}/get_list_all_user_info`;
const GET_LIST_FIELD_GROUP_INFO = `${SITES_BASE_URL}/get_list_field_group_info`;
const GET_DETAIL_SITE_URL = `${SITES_BASE_URL}/get_field_group_info`;
const GET_LIST_FIELD_USER_NOT_APPROVE = `${SITES_BASE_URL}/get_list_field_user_not_approve`;
const GET_COUNT_FIELD_USER_NOT_APPROVE = `${SITES_BASE_URL}/get_count_field_user_not_approve`;
const GET_USER_INFO_BY_ID= `${SITES_BASE_URL3}/get_user_info_by_id`;
const GET_FIELD_USER_INFO_BY_EMAIL = `${SITES_BASE_URL}/get_field_user_info_by_email`;
const DELETE_FIELD_GROUP_BY_IDS = `${SITES_BASE_URL}/delete_users`;
const DELETE_FIELD_USER_BY_IDS = `${SITES_BASE_URL}/delete_field_user_by_ids`;
const SET_STATUS_APPROVE = `${SITES_BASE_URL2}/set_sts_approve`;
const SET_STATUS_RECEIPT_ALLOCATION = `${SITES_BASE_URL2}/set_status_receipt_allocation`;
const BULK_ADD_FIELD_GROUP_INFO = `${SITES_BASE_URL}/bulk_add_field_group_info`;
const SELECT_USEABLE_FIELD_GROUP = `${SITES_BASE_URL}/select_useable_field_group`;
const ADD_FIELD_GROUP_INFO = `${SITES_BASE_URL}/add_field_group_info`;
const APPROVE_FIELD_USER_BY_IDS = `${SITES_BASE_URL}/approve_field_user_by_ids`;
const UPDATE_FIELD_GROUP_INFO = `${SITES_BASE_URL}/update_field_group_info`;
const GET_LIST_PARTNER_LEADER_IN_FIELD = `${SITES_URL}/fields/get_list_partner_leader_in_field`;

export const masterUsers = {
  getListAllUserInfo: (params) => {
    return Api.get(`${GET_LIST_ALL_USER_INFO}`, params);
  },

  getListFieldGroupInfo: (params) => {
    return Api.get(`${GET_LIST_FIELD_GROUP_INFO}`, params);
  },

  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}`, params);
  },

  getListFieldUserNotApprove: (params) => {
    return Api.get(`${GET_LIST_FIELD_USER_NOT_APPROVE}`, params);
  },

  getCountFieldUserNotApprove: (params) => {
    return Api.get(`${GET_COUNT_FIELD_USER_NOT_APPROVE}`, params);
  },

  getFieldUserInfoByEmail: (params) => {
    return Api.get(`${GET_FIELD_USER_INFO_BY_EMAIL}`, params);
  },

  deleteFieldUserByIds: (params) => {
    return Api.post(DELETE_FIELD_USER_BY_IDS, params);
  },

  setStatusApprove: (params) => {
    return Api.post(SET_STATUS_APPROVE, params);
  },

  setStatusReceiptAllocation: (params) => {
    return Api.post(SET_STATUS_RECEIPT_ALLOCATION, params);
  },

  deleteFieldGroupByIds: (params) => {
    return Api.post(DELETE_FIELD_GROUP_BY_IDS, params);
  },

  bulkAddFieldGroupInfo: (params) => {
    return Api.post(BULK_ADD_FIELD_GROUP_INFO, params, {headers: {'Content-Type': 'multipart/form-data'}});
  },

  selectUseableFieldGroup: (params) => {
    return Api.post(SELECT_USEABLE_FIELD_GROUP, params);
  },

  addFieldGroupInfo: (params) => {
    return Api.post(ADD_FIELD_GROUP_INFO, params);
  },

  approveFieldUserByIds: (params) => {
    return Api.post(APPROVE_FIELD_USER_BY_IDS, params);
  },

  updateFieldGroupInfo: (params) => {
    return Api.post(UPDATE_FIELD_GROUP_INFO, params);
  },
  getListPartnerLeaderInField: (params) => {
    return Api.get(GET_LIST_PARTNER_LEADER_IN_FIELD, params);
  },
  getUserInfoById: (params) => {
    return Api.get(GET_USER_INFO_BY_ID, params);
  },
}
