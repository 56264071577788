const FIELD_GROUP_TAB_SELECT = {
  FIELD_USER_INFO_TAB: {id: 1, name: "所属"},
  FIELD_USER_NOT_APPROVE_TAB: {id: 2, name: "利用者"}
}
const LIST_FIELD_GROUP_USERS_LABEL = [
  {
    text: "利用者名",
    align: "start",
    width: "30%",
    value: "user_name",
  },
  {
    text: "ID(携帯番号)",
    align: "start",
    width: "25%",
    isAct: true,
    value: "phone",
  },
  {
    text: "役割",
    align: "start",
    width: "20%",
    value: "role_name",
  },
  {
    text: "利用状態",
    align: "start",
    width: "20%",
    value: "usage_status_name",
  },
];
const ALL_USER_TABLE_LABELS = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "company_name",
    width:"20%",
  },
  {
    text: "氏名",
    align: "left",
    sortable: true,
    value: "user_name",
    width:"25%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    sortable: false,
    width:"25%",
  },
  {
    text: "携帯番号",
    value: "phone",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "種別",
    value: "type",
    align: "left",
    sortable: false,
    width:"15%",
  },
];

const FIELD_GROUP_TABLE_LABELS = [
  {
    text: "所属",
    align: "left",
    sortable: true,
    value: "name",
    width:"20%",
  },
  {
    text: "代表者",
    value: "leader_name",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "登録人数",
    value: "personnel_count",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "作成者",
    value: "create_user",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "最終更新者",
    value: "update_user",
    align: "left",
    sortable: false,
    width:"20%",
  },
];

const USER_TABLE_NOT_APPROVE_LABELS = [
  {
    text: "利用者名",
    align: "left",
    sortable: true,
    value: "user_name",
    width:"10%",
  },
  {
    text: "ID(携帯番号)",
    value: "phone",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "所属名",
    value: "group_name",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "所属代表者",
    value: "leader_name",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "立場",
    value: "role_name",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "利用状況",
    value: "usage_status_name",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "強制移動",
    value: "flg_forced_move",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "",
    value: "select_forced_move",
    align: "left",
    sortable: false,
    width:"20px",
  },
  {
    text: "荷受と割付・在庫登録",
    value: "flg_receipt_allocation",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "",
    value: "select_receipt_allocation",
    align: "left",
    sortable: false,
    width:"20px",
  },
];

const FIELD_GROUP_TABLE_LABELS_PARTNER = [
  {
    text: "所属",
    align: "left",
    sortable: true,
    value: "name",
    width:"20%",
  },
  {
    text: "代表者",
    value: "leader_name",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "登録人数",
    value: "personnel_count",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "作成者",
    value: "create_user",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "最終更新者",
    value: "update_user",
    align: "left",
    sortable: false,
    width:"20%",
  },
];

const ROLE_TYPE = {
  NUMBER_ONE: {id: 2, name: "職長"},
  NUMBER_TWO: {id: 3, name: "作業員"},
  NUMBER_THREE: {id: 4, name: "レンタル会社"},
};
const USE_STATUS_TYPE = {
  NUMBER_ZERO: {id: 0, name: "利用解除"},
  NUMBER_ONE: {id: 1, name: "利用中"},
};
const USER_TYPES = {
  NUMBER_ONE: {id: 1, name: "建設会社"},
  NUMBER_TWO: {id: 2, name: "レンタル会社"},
};
const ROLE_TYPE_DISPLAY = {
  NUMBER_ONE: {id: 1, name: "元請監督"},
  NUMBER_TWO: {id: 2, name: "協力職長"},
  NUMBER_THREE: {id: 3, name: "協力作業員"},
  NUMBER_FOR: {id: 4, name: "レンタル"},
};
const FIELD_GROUP_USER_ROLE_TYPE = {
  NUMBER_ONE: {id: 1, name: "元請"},
  NUMBER_TWO: {id: 2, name: "予約者"},
  NUMBER_THREE: {id: 3, name: "使用者"},
  NUMBER_FOUR: {id: 4, name: "レンタル会社"},
};
const FIELD_GROUP_PARTNER_USER_ROLE_TYPE = {
  NUMBER_TWO: {id: 2, name: "予約者"},
  NUMBER_THREE: {id: 3, name: "使用者"},
};
const ALL_USER_LIST_SORT_ITEMS = [
  {
    id: "company_name",
    name: "会社名",
  },
  {
    id: "user_name",
    name: "氏名",
  },
  {
    id: "email",
    name: "メールアドレス",
  },
];
const FIELD_GROUP_LIST_SORT_ITEMS = [
  {
    id: "name",
    name: "所属名",
  },
  {
    id: "leader_name",
    name: "代表者",
  },
  {
    id: "personnel_count",
    name: "登録人数",
  },
];
const FIELD_GROUP_USER_LIST_SORT_ITEMS = [
  {
    id: "name",
    name: "利用者名",
  },
  {
    id: "group_name",
    name: "所属名",
  },
];
const ROLE_NAME = {
  1: "管理者",
  2: "職長",
  3: "作業員",
  4: "レンタル会社",
}
const MAIN_CONTRACTOR_ROLE = 1
const PARTNER_RESERVER_ROLE = 2
const PARTNER_WORKER_ROLE = 3
const RENTAL_COMPANY_USER_ROLE = 4
const FIRST_LOAD = 0

const FORCED_MOVE = {
  0: "未設定",
  1: "永久権限付与",
  2: "１日限定権限付与",
}

const FORCED_MOVE_VIEW = {
  0: "",
  1: "承認済（永久）",
  2: "承認済（1日）",
}

const RECEIPT_ALLOCATION = {
  0: "未設定",
  1: "永久権限付与",
  2: "１日限定権限付与",
}

const RECEIPT_ALLOCATION_VIEW = {
  0: "",
  1: "承認済（永久）",
  2: "承認済（1日）",
}
const MESSAGES = {
  ERR_REQUIRE_SELECT_BUILDING_COMPANY : "現場関係会社を選択してください",
  ERR_EXISTS_EMPLOYEE_WITHOUT_LEADER : "職長の作業班に所属していない作業員がいます",
}

const USER_INIT = {
  email: '',
  phone: '',
  name_sei: '',
  name_mei: '',
  kana_sei: '',
  kana_mei: '',
}

export {
  FIELD_GROUP_TAB_SELECT,
  LIST_FIELD_GROUP_USERS_LABEL,
  ALL_USER_TABLE_LABELS,
  FIELD_GROUP_TABLE_LABELS,
  ROLE_TYPE,
  USER_TYPES,
  USE_STATUS_TYPE,
  FIELD_GROUP_USER_ROLE_TYPE,
  FIELD_GROUP_PARTNER_USER_ROLE_TYPE,
  FIELD_GROUP_LIST_SORT_ITEMS,
  ALL_USER_LIST_SORT_ITEMS,
  FIELD_GROUP_USER_LIST_SORT_ITEMS,
  USER_TABLE_NOT_APPROVE_LABELS,
  FIELD_GROUP_TABLE_LABELS_PARTNER,
  ROLE_NAME,
  MESSAGES,
  MAIN_CONTRACTOR_ROLE,
  PARTNER_RESERVER_ROLE,
  PARTNER_WORKER_ROLE,
  RENTAL_COMPANY_USER_ROLE,
  FIRST_LOAD,
  ROLE_TYPE_DISPLAY,
  USER_INIT,
  FORCED_MOVE,
  FORCED_MOVE_VIEW,
  RECEIPT_ALLOCATION_VIEW,
  RECEIPT_ALLOCATION
}
