<template>
  <div>
    <Loading v-if="isLoading"/>
    <v-app-bar class="portalHeader" dense>
      <!-- 自社ポータルグローバルヘッダー -->
      <!-- タイトル -->
      <div
        class="portalHeader-title font-weight-bold primary--text"
        style="display: flex"
        mx-3
        @click="close"
        :class="adminClass"
      >
        <div class="cursor-title" @click="goToHomePage" style="margin-top: 6px;">
          <img src="/images/Airliza.png" alt="-image" width="160" height="30" class=""/>
        </div>
        <div class="cursor-title" @click="goToHomePage" style="font-size: 17px; margin-top: 10px; margin-left: 35px;">
          {{ title }}
        </div>
      </div>

      <!-- 現場選択ダイアログ -->
      <v-btn
        dense
        filled
        depressed
        color="grey lighten-2"
        class="site_select_btn"
        @click="openSearchDialog"
        v-if="!isChooseField"
      >
        <!-- 現場名 -->
        <div class="sites_name_wrapper">
          {{ CurrentSite ? CurrentSite.field_name : "" }}
        </div>
        <v-spacer></v-spacer>
        <v-icon dark right> mdi-menu-down </v-icon>
      </v-btn>

      <div class="width-hidden" :class="adminClass" v-else></div>

      <!-- Company name -->
      <span v-if="!admin" class="portalHeader-date primary--text font-weight-bold company-name" @click="close">
        {{ companyName }}
      </span>

      <!-- 日付 -->
      <span class="portalHeader-date primary--text font-weight-bold" @click="close">
        {{ getFormattedDate() }}
      </span>

      <v-spacer></v-spacer>

      <!-- 自社ポータル タブメニュー -->
      <v-tabs portalHeader-tabmenu right v-model="active_tab">
        <v-tab
          v-for="val in headerMenuItems"
          v-show="val.visibility"
          :key="val.id"
          @change="(menu = val), onChange(val.id)"
          @click="companyRoute(val)"
          >{{ val.name }}</v-tab
        >
      </v-tabs>

      <!-- 通知 -->
      <v-btn :disabled="isNotice" icon @click="openNotification">
        <v-icon v-if="isUpdated" class="buruburu">mdi-bell</v-icon>
        <v-icon v-if="!isUpdated">mdi-bell-outline</v-icon>
      </v-btn>

      <!-- drawer -->
      <v-btn icon @click="openDrawer">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
    </v-app-bar>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場選択"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import {
  PORTAL_LIST,
  HEADER_MENU_ITEMS_ADMIN,
  HEADER_MENU_ITEMS_FIELD,
  HEADER_MENU_ITEMS_RENTAL,
} from "@/constants/GLOBALHEADER";
import { 
  NO_DATA
} from "@/constants/COMMON";
import { getEnvConfigValue, ENV_CONFIG_LABELS } from "@/constants/ENV_CLIENT";
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";
import Loading from "@/components/loading/Loading";

const FIELD_GROUP_STORE = "FieldGroups";
const HEADR_MENU_ITEMS_FIELD = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_FIELD
  );
  return Object.keys(HEADER_MENU_ITEMS_FIELD).map((key) => {
    return {
      ...HEADER_MENU_ITEMS_FIELD[key],
      ...menuConfig[key],
    };
  });
};

const HEADR_MENU_ITEMS_RENTAL = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_RENTAL
  );
  return Object.keys(HEADER_MENU_ITEMS_RENTAL).map((key) => {
    return {
      ...HEADER_MENU_ITEMS_RENTAL[key],
      ...menuConfig[key],
    };
  });
};

const HEADR_MENU_ITEMS_ADMIN = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_ADMIN
  );
  return Object.keys(HEADER_MENU_ITEMS_ADMIN).map((key) => {
    return {
      ...HEADER_MENU_ITEMS_ADMIN[key],
      ...menuConfig[key],
    };
  });
};

const ROLE_MAIN = 1;
const ACTIVE_FLG_APPLY_FOR_TERMINATION = 2;
const ACTIVE_FLG_END = 3;

export default {
  data() {
    return {
      title: null,

      // タブメニュー表示項目
      headerMenuItems: [],

      // 選択中のタブメニューid
      active_tab: 0,

      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
        selectedFieldUser: null,
      },
      // 検索ダイアログ表示
      isShowSearchDialog: false,

      // 更新があるか
      isUpdated: false,
      companyName: null,
      isChooseField: null,
      admin: null,
      adminClass: null,
      isNotice: false,
      isLoading: false
    };
  },

  components: {
    FieldsSelectDialog,
    Popup,
    Loading
  },

  props: {
    companyId: Number,
  },

  async mounted() {
    // do not show 支店 if user is belong with a Office

    const companyUser = JSON.parse(sessionStorage.getItem("USER")).Login.user;

    if (companyUser?.company?.name) {
      this.companyName = companyUser.company.name;
    }

    if (companyUser?.company?.company_type == 2) {
      this.isChooseField = 1;
    }
      
    if (companyUser?.admin_flg) {
      this.headerMenuItems = HEADR_MENU_ITEMS_ADMIN();
      this.isChooseField = 1;
      this.admin = 1;
      this.title = '管理画面（事務局側）';
      this.adminClass = 'admin-title-header'
    }
    if ((!companyUser?.company || companyUser?.company?.company_type == 1) && !companyUser?.admin_flg) {
      this.headerMenuItems = HEADR_MENU_ITEMS_FIELD();
    }
    if (companyUser?.company?.company_type == 2 && !companyUser?.admin_flg) {
      this.headerMenuItems = HEADR_MENU_ITEMS_RENTAL();
    }
    /**
     * 更新のチェック
     */
    this.$watch(
      () => Store.getters[`Notification/countUnreadNotification`],
      (value) => {
        if (value != null) {
          this.isUpdated = value === 1;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getInHouseMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧の取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Notification/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },

  methods: {
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      this.close();
      const selectedMenu = this.headerMenuItems.find((item) => {
        return item.id === menuId;
      });

      this.$router.push(selectedMenu.path);
    },

    // ドロワー
    openDrawer() {
      this.close();
      Store.dispatch("Drawer/show", {
        type: PORTAL_LIST.INHOUSE_PORTAL.id,
      });
    },

    // 通知
    async openNotification(isShowLoading = true) {
      this.close();
      this.isNotice = true;
      const result = await Store.dispatch("Notification/show", isShowLoading);
      this.isNotice = false;
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      }
    },

    /**
     * 現場選択ダイアログを開く
     */
    async openSearchDialog() {
      this.close();
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      const { entries } = result.data.contents;
      if (result.hasError || entries.length === 0) {
        Store.dispatch("Error/show", {
          status: 200,
          message: NO_DATA,
        });
        return;
      }
      this.isShowSearchDialog = true;
    },

    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    goToHomePage() {
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      this.$router.push("/master/fields");
    },

    // フォームの変更を受け取る
    async formUpdate(values) {
      this.formValues = { ...values };
      let arr = Object.values(this.formValues?.items);
      arr = arr.filter((e) => {
        return e.field_id === this.formValues?.selected;
      });
      let onlyReport = false;
      if (arr[0] &&
        arr[0]?.role === ROLE_MAIN &&
        (
          arr[0]?.active_flg === ACTIVE_FLG_APPLY_FOR_TERMINATION ||
          arr[0]?.active_flg === ACTIVE_FLG_END
        )
      ) {
        onlyReport = true;
      }
      
      /**
       * 現場選択
       */
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });

        if (onlyReport) {
          this.$router.push("/reports");
        } else {
          let params = { field_user_id: this.formValues.selectedFieldUser}
          const service = await Store.dispatch(
            `${FIELD_GROUP_STORE}/selectUseableFieldGroup`,
            params
          );
          if (service.data.result) {
            // 現場ポータルに遷移
            this.$router.push("/booking");
          }
        }
        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;
        // ダイアログclose
        this.isShowSearchDialog = false;
      }
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },

    close() {
      Store.dispatch("Drawer/close");
    },

    // companyRoute(val) {
    //   if (val.isRoute) {
    //     if (this.$route.path == '/master/companies/list_user_by_company') {
    //       this.$router.push("/master/companies");
    //     }
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/responsive.scss";

.portalHeader {
  .portalHeader-title {
    font-size: 16px;
    width: 128px;
    margin-right: 80px;
  }
  .portalHeader-siteSelect {
    width: 224px;
  }
  .portalHeader-date {
    padding-left: 20px;
    white-space: nowrap;
  }
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}

.site_select_btn {
  width: 220px;
  min-width: 180px !important;
  overflow: hidden;
  @media (max-width: $sp) {
    width: 140px;
    min-width: 140px !important;
  }
}
.site_select_btn.v-btn.v-size--default {
  padding: 4px !important;
}
.sites_name_wrapper {
  width: auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
}

@media (max-width: 1024px) {
  .portalHeader-date.company-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 24vw;
    min-width: 16vw;
  }
}


.cursor-title {
  cursor: pointer;
  white-space: nowrap;
}

.width-hidden{
  width: 21.484375vw
}

.width-hidden.admin-title-header {
  min-width: 220px;
}

.portalHeader .portalHeader-title.admin-title-header {
  width: 68.3125vw;
}

@media (max-width: 1024px) {
  .portalHeader-title.font-weight-bold.primary--text.admin-title-header {
    width: 17vw;
  }
  .width-hidden.admin-title-header {
    min-width: 21.484375vw;
    width: unset !important;
  }
}


.portalHeader .v-tabs .v-tab {
  font-weight: 700;
}

div::v-deep .portalHeader .mdi-bell-outline {
  color: #274fc2;
}
div::v-deep .portalHeader .mdi-menu {
  color: #274fc2;
}

@keyframes hurueru {
  0% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(10deg);
  }
}

.buruburu {
  display: inline-block;
  animation: hurueru 1s infinite;
  transition-timing-function: ease-in-out;
  &::before {
    color: $color_util_1;
  }
}
</style>
