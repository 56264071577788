import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/companies`;
const GET_LIST_URL = `${SITES_BASE_URL}/get_list_master_company_info`;
const DELETE_URL = `${SITES_BASE_URL}/delete_company_by_ids`;
const GET_DETAIL_SITE_URL = `${SITES_BASE_URL}/get_company_info_by_id`;
const GET_LIST_COMPANY_BUILDING = `${SITES_BASE_URL}/get_list_building_company`;
const EDIT_URL = `${SITES_BASE_URL}/update_company_info`;
const CREATE_URL = `${SITES_BASE_URL}/add_master_company_info`;

const GET_LIST_USER_URL = `${SITES_BASE_URL}/get_list_user_by_company`;
const GET_USER_INFO_BY_ID = `${SITES_BASE_URL}/get_user_info_by_id`;
const UPDATE_USER_INFO = `${SITES_BASE_URL}/update_user_info`;
const DELETE_USER_BY_IDS = `${SITES_BASE_URL}/delete_user_by_ids`;
const ADD_USER_INFO = `${SITES_BASE_URL}/add_user_info`;
const ACTIVE_USER = `${SITES_BASE_URL}/active_user`;
const DEACTIVE_USER = `${SITES_BASE_URL}/deactive_user`;
const ADD_USERS_INFO = `${SITES_BASE_URL}/add_all_users`;
const CHECK_FIELD_COMPANY_BY_CORPORATE_NUMBER = `${SITES_BASE_URL}/check_field_company_by_corporate_number`;
const CHECK_BOOKING_INFO_BY_SUB_LEADER = `${SITES_BASE_URL}/get_booking_info_by_sub_leader`;
const GET_BOOKING_INFO_BY_FIELD_MACHINE = `${SITES_BASE_URL}/get_booking_info_by_field_machine`;

export const companies = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getListUserByCompany: (params) => {
    return Api.get(GET_LIST_USER_URL, params);
  },
  getUserInfoById: (params) => {
    return Api.get(GET_USER_INFO_BY_ID, params);
  },
  updateUserInfo: (params) => {
    return Api.post(UPDATE_USER_INFO, params);
  },
  addUserInfo: (params) => {
    return Api.post(ADD_USER_INFO, params);
  },
  activeUser: (params) => {
    return Api.post(ACTIVE_USER, params);
  },
  deactiveUser: (params) => {
    return Api.post(DEACTIVE_USER, params);
  },
  deleteUserByIds: (params) => {
    return Api.post(DELETE_USER_BY_IDS, params);
  },
  add: (params) => {
    return Api.post(CREATE_URL, params);
  },
  edit: async (params) => {
    return Api.post(EDIT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}`, params);
  },
  getListBuilding: (params) => {
    return Api.get(GET_LIST_COMPANY_BUILDING, { params });
  },
  addUsersInfo: (params) => {
    return Api.post(ADD_USERS_INFO, params);
  },
  checkFieldCompanyByCorporateNumber: (params) => {
    return Api.get(CHECK_FIELD_COMPANY_BY_CORPORATE_NUMBER, params);
  },
  getBookingInfoBySubLeader: (params) => {
    return Api.get(CHECK_BOOKING_INFO_BY_SUB_LEADER, params);
  },
  getBookingInfoByFieldMachine: (params) => {
    return Api.get(GET_BOOKING_INFO_BY_FIELD_MACHINE, params);
  }
};
